import { Box, Tab, Tabs } from "@mui/material";
import React, { useContext, useEffect } from "react";
import TabPanel2 from "../../../../../components/widgets/TabPanel2";
import NewViewProject from "../../../../admin/ViewProject2";
import {
  fullPageBoxStyle,
  verticalTabStyle,
} from "../../../../../styles/muiProps";
import ITPTable from "./ITP";
import PunchList from "./Punchlist";
import { useParams } from "react-router-dom";
import PCSTable from "./Table";
import NavTabs from "../../../../../components/Tabs/NavTabs";
import FabricationTab from "./Fabrication";
import ProjectTasksPage from "../../../Project/allProjectsPage/ProjectsTable/ProjectTasksPage";
const PCS = (props) => {
  // const { projectId, equipID: equipId } = props;
  const { projectId, equipId } = useParams();
  const [tab, setTab] = React.useState(0); ///value of tab

  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  const tabs = [
    // {
    //   label: "RACI",
    //   panel: <PCSTable projectId={projectId || ""} type="RACI" />,
    // },
    {
      label: "Fabrication",
      // panel: <NewViewProject projectId={projectId || ""} />,
      panel: <FabricationTab />,
    },
    {
      label: "ITP ",
      panel: <ITPTable />,
    },
    {
      label: "Task List",
      panel: (
        <ProjectTasksPage
          catList={[
            { title: "FINAL PREPARATION" },
            { title: "HYDROSTATIC TEST" },
            { title: "PWHT & NDE POST PWHT" },
            { title: "MANUFACTURING & NDE" },
          ]}
        />
      ),
    },
    // {
    //   label: "Procedures",
    //   panel: <></>
    // },
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        "& .Mui-disabled": {
          color: "red",
          "-webkit-text-fill-color": "#000000",
        },
      }}
      {...fullPageBoxStyle}
    >
      {/* <CustomAlert open={openAlert} setOpen={setOpenAlert} severity={AlertContent.severity} message={AlertContent.message} /> */}

      <NavTabs
        tabs={tabs}
        // tabProps={verticalTabStyle}
        navLabel="pcs-tab"
        // vertical={true}
      />

      {/* <Tabs
        orientation="vertical"
        value={tab}
        onChange={handleChange}
        {...verticalTabStyle}
      >
        <Tab label="RACI" />
        <Tab label="Fabrication" />
        <Tab label="ITP" />
        <Tab label="Punch List" />
        <Tab label="Procedures" />
      </Tabs>
      <TabPanel2 value={tab} index={0}>
        <PCSTable projectId={projectId || ""} type="RACI" />
      </TabPanel2>
      <TabPanel2 value={tab} index={1}>
        <PCSTable projectId={projectId || ""} type="Fabrication" />
      </TabPanel2>
      <TabPanel2 value={tab} index={2} p={1}>
        <PCSTable projectId={projectId || ""} type="ITP" />
      </TabPanel2>
      <TabPanel2 value={tab} index={3} p={1}>
        <PCSTable projectId={projectId || ""} type="PunchList" />
      </TabPanel2>
      <TabPanel2 value={tab} index={4} p={1}>
        <PCSTable projectId={projectId || ""} type="Procedures" />
      </TabPanel2>
        <PunchList equipID={equipId} />
      </TabPanel2> */}
    </Box>
  );
};

export default PCS;
