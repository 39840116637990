import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Row from './Row';
import { mainMaterialsTablesCols } from './col';

export default function MaterialTable({ materials }) {
  console.log({ mm: materials?.[0] });
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" sx={{ border: '1px solid #ddd' }}>
        <TableHead>
          <TableRow
            sx={{ border: '1px solid #ddd', backgroundColor: '#f3f1fe' }}
          >
            <TableCell />
            {mainMaterialsTablesCols.map((col, i) => {
              return (
                <TableCell
                  key={col.header + i}
                  sx={{
                    fontweight: 'bold',
                    fontSize: '17px',
                    border: '1px solid #ddd',
                  }}
                >
                  {col.header}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {materials.map((row) => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
