import React from "react";

import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

import {
  bulkDrawingMaterialsCols,
  taggedDrawingMaterialsCols,
} from "../cols/drawingsMaterialsCols";
import { lightTable } from "../../../../../../../styles/muiProps";

export default function DrawingsMaterialsTable({ materials }) {
  const table = useMaterialReactTable({
    columns:
      materials?.[0]?.tagNumber || materials?.[0]?.taggedMaterial?.tagNumber
        ? taggedDrawingMaterialsCols
        : bulkDrawingMaterialsCols,
    data: materials,
    ...lightTable,
    enableStickyHeader: true,

    muiTableContainerProps: { sx: { maxHeight: "350px" } },
  });

  return <MaterialReactTable table={table} />;
}
