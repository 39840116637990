import { Box, Modal } from "@mui/material";
import React from "react";
import AddEntityForm from "../../Components/Forms/AddEntityForm";
import AllocateToDrawingMaterialsComponent from "../../Components/Tables/AllocateToDrawingMaterialsComponent";
import { useMcsContext } from "../../MCS.context";
import { modalStyle } from "../../../../../../../components/modalStyle";

export default function AddMrvModal({
  openAddMrvModal,
  setOpenAddMrvModal,
  selectedPoMaterials,
  setSelectedPoMaterials,
}) {
  const { project } = useMcsContext();

  const [selectedDrawingMaterials, setSelectedDrawingMaterials] =
    React.useState([]);

  const onCloseModal = () => {
    setOpenAddMrvModal(false);
  };

  const drawingMaterials = [];

  selectedPoMaterials.forEach((mm) => {
    drawingMaterials.push(...mm.materials);
  });

  const onAllocateAll = () => {
    if (drawingMaterials.length !== selectedDrawingMaterials.length) {
      setSelectedDrawingMaterials([]);
      setSelectedDrawingMaterials(drawingMaterials);
    } else {
      setSelectedDrawingMaterials([]);
    }
  };

  return (
    <Modal open={openAddMrvModal} onClose={onCloseModal}>
      <Box sx={modalStyle}>
        <AddEntityForm
          entityType="mrv"
          table={
            <AllocateToDrawingMaterialsComponent
              mrqMaterials={selectedPoMaterials}
              setMrqMaterials={setSelectedPoMaterials}
              selectedDrawingMaterials={selectedDrawingMaterials}
              setSelectedDrawingMaterials={setSelectedDrawingMaterials}
            />
          }
          mrqMaterials={selectedPoMaterials}
          drawingMaterials={selectedDrawingMaterials}
          onCloseModal={onCloseModal}
          onAllocateAll={onAllocateAll}
          mrqId={project.selectedPo?.mrq?.id}
        />
      </Box>
    </Modal>
  );
}
