import React from 'react';

import { MrqContextProvider } from './MrqContext';
import Main from './Main';

const MRQTab = () => {
  return (
    <MrqContextProvider>
      <Main />
    </MrqContextProvider>
  );
};

export default MRQTab;
