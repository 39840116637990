import { Navigate } from "react-router-dom";

import Contact from "../../pages/public/Contact";
import paths from "../../api/paths.api";
import CompanyForm from "../../pages/client/CompanyForm";
import ProjectPage from "../../pages/client/Project/ProjectPage";
import EquipmentsPage from "../../pages/client/Project/ProjectPage/ProjectNestedPages/EquipmentsPage";

//--------Guest-------
import GuestRegister from "../../pages/public/guest/GuestRegisterEmail";
import GuestDetails from "../../pages/public/guest/GuestRegisterDetails";
import GuestLogin from "../../pages/public/guest/GuesttLogin";
import GuestResetPwdEmail from "../../pages/public/guest/GuestResetPwdEmail";
import GuestResetPwd from "../../pages/public/guest/GuestResetPwd";
import MainProjects from "../../pages/client/Project/allProjectsPage";
import ProjectForm from "../../pages/client/Project/ProjectForm";
import EquipForm from "../../pages/client/Project/ProjectForm/EquipsForm";

//--------Staff-------
import StaffLogin from "../../pages/staff/auth/StaffLogin";
import StaffRegisterPassword from "../../pages/staff/auth/StaffRegisterPassword";
import StaffResetPwdEmail from "../../pages/staff/auth/StaffResetPwdEmail";
import StaffResetPwd from "../../pages/staff/auth/StaffResetPwd";
import EquipmentPage from "../../pages/client/EquipmentPage";

// Equipment types
import MainPipe from "../../pages/client/AllEquipments/Piping";
import MainStructure from "../../pages/client/AllEquipments/Structural";
import MainInstrument from "../../pages/client/AllEquipments/Instrumentation";
import MainSkidPackage from "../../pages/client/AllEquipments/SkidPackage";
import MainPressureVessel from "../../pages/client/AllEquipments/PressureVessel";
import TankMain from "../../pages/client/AllEquipments/Tank/Main";
import MaterialsDBPage from "../../pages/client/MaterialsDBPage";
import SCM from "../../pages/client/SCMPage";
import SoftwarePage from "../../pages/client/SoftwarePage";
import SolutionPage from "../../pages/client/Solutions";
import MsLoginPage from "../../pages/MicrosoftLoginPage";
import ProjectTasksPage from "../../pages/client/Project/allProjectsPage/ProjectsTable/ProjectTasksPage";
import { DCSProvider } from "../../pages/client/AllEquipments/CommonFeatures/DCSTab/Table/DCSContext";
export const mainRoutes = [
  {
    path: paths.main_pv,
    element: <MainPressureVessel />,
  },
  {
    path: paths.main_pv_tabs,
    element: <MainPressureVessel />,
  },
  {
    path: paths.materialsDB,
    element: <MaterialsDBPage />,
  },
  {
    path: paths.scm,
    element: <SCM />,
  },
  {
    path: "/equipment/main_pv",
    element: <EquipmentPage />,
  },

  {
    index: true,
    element: <Navigate to="/home" />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },

  {
    path: "/dev/register/:token",
    element: <StaffRegisterPassword />,
  },
  {
    path: "/login",
    element: <MsLoginPage />,
  },
  {
    path: "/dev/login",
    element: <StaffLogin />,
  },
  {
    path: "/dev/resetPassword",
    element: <StaffResetPwdEmail />,
  },
  {
    path: "/dev/resetPassword/:token",
    element: <StaffResetPwd />,
  },
  {
    path: "/guest/register",
    element: <GuestRegister />,
  },
  {
    path: "/guest/register/:token",
    element: <GuestDetails />,
  },
  {
    path: "/guest/login",
    element: <GuestLogin />,
  },
  {
    path: "/guest/resetPassword",
    element: <GuestResetPwdEmail />,
  },
  {
    path: "/guest/resetPassword/:token",
    element: <GuestResetPwd />,
  },
  {
    path: paths.myProjects,
    element: <MainProjects />,
  },
  {
    path: paths.projectFormApi,
    element: <ProjectForm />,
  },
  {
    path: paths.projectEquipsApi,
    element: <EquipForm />,
  },
  {
    path: paths.getAProject,
    element: <ProjectPage />,
    // children: [{ path: paths.EquipmentsApi, element: <EquipmentsPage /> }],
  },
  {
    path: paths.softwares,
    element: <SoftwarePage />,
  },
  {
    path: "/start-company",
    element: <CompanyForm />,
  },
  {
    path: "/projects/:id/equipments/main_tank",
    element: (
      <DCSProvider>
        <TankMain />
      </DCSProvider>
    ),
  },
  {
    path: "/projects/:id/equipments/piping",
    element: <MainPipe />,
  },
  {
    path: "/projects/:id/equipments/structural",
    element: <MainStructure />,
  },
  {
    path: "/projects/:id/equipments/instrumentation",
    element: <MainInstrument />,
  },
  {
    path: "/projects/:id/equipments/skid-package",
    element: <MainSkidPackage />,
  },
  {
    path: "/projects/:id/task",
    element: <ProjectTasksPage />,
  },
  {
    path: "/solutions",
    element: <SolutionPage />,
  },
];
