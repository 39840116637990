export const taggedDrawingMaterialsCols = [
  {
    accessorKey: 'tagNumbers',
    header: 'Tag Number',
    enableEditing: false,
    grow: false, //don't allow this column to grow to fill in remaining space
    size: 50,
    Cell: ({ row }) => {
      const m = row.original;
      return m?.taggedMaterial?.tagNumber;
    },
  },
  {
    header: 'Description',
    accessorKey: 'description',
    enableEditing: false,
    size: 200,
    Cell: ({ row }) => {
      const m = row.original;
      return m.description
        ? m.description
        : `${m.taggedMaterial?.component} ${m.taggedMaterial?.size}`;
    },
  },
  {
    header: 'Quantity',
    accessorKey: 'quantity',
    enableEditing: false,
    size: 50,
  },
  {
    header: 'Remarks',
    accessorKey: 'remarks',
    enableEditing: false,
    size: 150,
  },
];

export const bulkDrawingMaterialsCols = [
  {
    accessorKey: 'item',
    header: 'Item',
    enableEditing: false,
    grow: false,
    size: 50,
  },
  {
    accessorKey: 'quantity',
    header: 'Quantity',
    enableEditing: false,
    grow: false,
    size: 50,
  },
  // {
  //   accessorKey: 'unit',
  //   header: 'Unit',
  //   enableEditing: false,
  //   grow: false,
  //   size: 50,
  // },
  {
    accessorKey: 'materialCode',
    header: 'Material Code',
    enableEditing: false,

    size: 120,
  },
  {
    accessorKey: 'description',
    header: 'Description',
    enableEditing: false,

    size: 120,
  },
];

export const bulkDescriptionDrawingMaterialsCols = [
  {
    accessorKey: 'item',
    header: 'Mark #',
    enableEditing: false,
    grow: false,
    size: 120,
  },
  {
    accessorKey: 'partNumber',
    header: 'Part #',
    enableEditing: false,
    grow: false,
    size: 50,
  },
  {
    accessorKey: 'tagNumber',
    header: 'Tag #',
    enableEditing: false,
    grow: false,
    size: 50,
  },
  {
    accessorKey: 'description',
    header: 'Description',
    enableEditing: false,

    size: 120,
  },
  {
    accessorKey: 'quantity',
    header: 'Qty',
    enableEditing: false,
    grow: false,
    size: 20,
  },

  {
    accessorKey: 'unit',
    header: 'Unit',
    enableEditing: false,

    size: 20,
  },
  {
    accessorKey: 'wt',
    header: 'wt. (kg)',
    enableEditing: false,

    size: 60,
  },
];

export const drawingMaterialsCols = (materials) => {
  const rows = materials || [];
  const isTaggedMaterials = rows?.[0]?.taggedMaterial?.id;
  const isBulkMaterials = rows?.[0]?.materialCode;

  const cols = isTaggedMaterials
    ? taggedDrawingMaterialsCols
    : isBulkMaterials
      ? bulkDrawingMaterialsCols
      : bulkDescriptionDrawingMaterialsCols;

  return cols;
};
