import { useContext, useEffect, useMemo, useState } from "react";
import {
  MRT_EditActionButtons,
  MRT_ExpandAllButton,
  MRT_ExpandButton,
  MaterialReactTable,
  createRow,
  // createRow,
  useMaterialReactTable,
} from "material-react-table";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
} from "@mui/material";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArchiveIcon from "@mui/icons-material/Archive";
import { AuthContext } from "../../context/authContext";
import AddIcon from "@mui/icons-material/Add";

export const EditableTable = ({
  name,
  columns,
  actions,
  hooks,
  editMode,
  enableEditing,
  enableDelete = false,
  enableArchive,
  additionalProps,
  initialData,
  enableCreate,
  data,
}) => {
  const { accessToken } = useContext(AuthContext);
  const [validationErrors, setValidationErrors] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  console.log({ name, enableDelete });
  //call CREATE hook
  const { mutateAsync: createItem, isPending: isCreatingItem } =
    hooks.useCreateItem();
  //call READ hook
  const {
    data: fetchedItems = [],
    isError: isLoadingItemsError,
    isFetching: isFetchingItems,
    isLoading: isLoadingItems,
  } = hooks.useGetItems({ accessToken });
  //call UPDATE hook
  const { mutateAsync: updateItem, isPending: isUpdatingItem } =
    hooks.useUpdateItem();
  //call DELETE hook
  const { mutateAsync: deleteItem, isPending: isDeletingItem } =
    hooks.useDeleteItem();

  //CREATE action
  const handleCreateItem = async ({ values, table }) => {
    // console.log("creating", values);

    if (actions) {
      const newValidationErrors = actions.validateItem(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        // console.log("error::::retu", newValidationErrors);
        return;
      }
    }
    // console.log("error::::", newValidationErrors);
    setValidationErrors({});
    await createItem({ type: name, values, accessToken });
    table.setCreatingRow(null); //exit creating mode
  };

  //UPDATE action
  const handleSaveItem = async ({ values, row, table }) => {
    console.log("updted row", row, values);

    if (actions) {
      const newValidationErrors = actions.validateItem(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        // console.log("error::::retu", newValidationErrors);
        return;
      }
    }
    // console.log("error::::", newValidationErrors);
    setValidationErrors({});
    setValidationErrors({});
    // console.log({ values, row })
    await updateItem({ values: { ...values, id: row.id }, accessToken });
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row) => {
    if (window.confirm(`Are you sure you want to delete this ${name}?`)) {
      deleteItem({ id: row.original.id, accessToken: accessToken });
    }
  };

  const openArchiveConfirmModal = (row) => {
    if (window.confirm(`Are you sure you want to archive this ${name}?`)) {
      actions.archiveItem({ id: row.original.id, accessToken: accessToken });
    }
  };

  const table = useMaterialReactTable({
    columns: columns,
    data: data ? data : fetchedItems,
    createDisplayMode: editMode, //default ('row', and 'custom' are also available)
    editDisplayMode: editMode, //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: enableEditing,
    getRowId: (row) => row.id,
    muiSkeletonProps: {
      animation: "wave",
    },
    muiLinearProgressProps: {
      color: "secondary",
    },
    muiCircularProgressProps: {
      color: "secondary",
    },
    muiToolbarAlertBannerProps: isLoadingItemsError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateItem,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveItem,
    positionActionsColumn: "last",
    //optionally customize modal content
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h5">Create New {name}</DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    //optionally customize modal content
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h5">Edit {name}</DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        {enableDelete ? (
          <Tooltip title="Delete">
            <IconButton
              color="error"
              onClick={() => openDeleteConfirmModal(row)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
        {enableArchive ? (
          <Tooltip title="Archive">
            <IconButton
              color="error"
              onClick={() => openArchiveConfirmModal(row)}
            >
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
        {/* <Tooltip title="Delete">
                    <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip> */}
      </Box>
    ),
    // displayColumnDefOptions: {
    //     'mrt-row-actions': {
    //         header: 'Edit', //change "Actions" to "Edit"
    //         //use a text button instead of a icon button
    //         Cell: ({ row, table }) => (
    //             <Stack direction={"row"}>

    //                 <Tooltip title="Edit">
    //                     <IconButton onClick={() => table.setEditingRow(row)}>
    //                         <EditIcon />
    //                     </IconButton>
    //                 </Tooltip>
    //                 {
    //                     enableDelete ?
    //                         <Tooltip title="Delete">
    //                             <IconButton onClick={() => openDeleteConfirmModal(row)}>
    //                                 <DeleteIcon />
    //                             </IconButton>
    //                         </Tooltip>
    //                         : <></>
    //                 }
    //             </Stack>
    //             // <Button onClick={() => table.setEditingRow(row)}>Edit Customer</Button>
    //         ),
    //     },
    // },

    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        sx={{ display: enableCreate ? "flex" : "none" }}
        disabled={
          !enableCreate || isLoadingItems || isUpdatingItem || isCreatingItem
        }
        startIcon={<AddIcon />}
        color="secondary"
        onClick={() => {
          table.setCreatingRow(createRow(table, initialData));
        }}
      >
        Add {name}
      </Button>
    ),
    positionGlobalFilter: "left",
    ...(additionalProps ? { ...additionalProps } : {}),
    state: {
      isLoading: isLoadingItems,
      isSaving: isCreatingItem || isUpdatingItem || isDeletingItem,
      showAlertBanner: isLoadingItemsError,
      showProgressBars: isFetchingItems,
    },
    initialState: { columnVisibility: { id: false }, showGlobalFilter: true },
  });

  // return <MaterialReactTable table={table} />;
  return (
    <Box sx={{ mt: 0 }}>
      {/* <ActionBar table={table} initialData={initialData} name={name} enableCreate={enableCreate} /> */}
      <MaterialReactTable table={table} />
    </Box>
  );
};

const validateRequired = (value) => !!value.length;

function validateItem(user) {}
