// import axios as autodesk_axios, { secondAxios as axios } from "../../common/api/axios";
import axios, { secondAxios } from "../../../../api/axios";

export const getProject = async (projectId) => {
  const response = await axios.get(`/project/${projectId}`);
  return response.data;
};
export const getAllProjects = async (parentId, accessToken) => {
  // const response = await axios.get('/project');
  console.log("client nameee", process.env.REACT_APP_CLIENT_NAME);
  const response = await axios.get(
    `/project/all/${process.env.REACT_APP_CLIENT_NAME}`,
    { headers: { authorization: `Bearer ${accessToken}` } }
  );
  const modifiedRes = response.data.map((res) => ({
    ...res,
    members: res["project_members"],
  }));
  console.log("projjj", modifiedRes);

  return modifiedRes;
};

export const createProject = async (parentId, project, accessToken) => {
  console.log("creating", { project }, process.env.REACT_APP_CLIENT_NAME);
  const { progress, id, ...reducedProject } = project;
  const resp1 = await axios.post(
    "/project/create",
    {
      projectName: project.projectName,
      clientName: process.env.REACT_APP_CLIENT_NAME,
    },
    { headers: { authorization: `Bearer ${accessToken}` } }
  );
  console.log("created", resp1);
  const body = {
    projectName: resp1.data.project.projectName,
    id: resp1.data.project.id,
  };
  console.log("body", body);
  await axios.post(
    `/project/create/${process.env.REACT_APP_CLIENT_NAME}`,
    body
  );
  if (project.members && project.members.length > 0) {
    project.members.forEach(async (member) => {
      await axios.post("/project/add-member", {
        projectId: body.id,
        userId: member.id,
        role: member.role,
      });
    });
  }
};

export const updateProject = async (project, accessToken) => {
  const { id, progress, ...reducedProject } = project;

  await axios.patch(`/project/${project.id}`, reducedProject, {
    headers: { authorization: `Bearer ${accessToken}` },
  });

  const reshapeProjectUsers = (p) => {
    const users = p.users.map((u) => {
      return {
        id: u?.id,
        userId: u.userId,
        role: u.role,
        projectId: project.id,
      };
    });
    return users;
  };
  await axios.patch(
    `/project/update-user-role/${project.id}`,
    reshapeProjectUsers(project)
  );
};

export const deleteProject = async (projectId, accessToken) => {
  await axios.delete(`/project/${projectId}`, {
    headers: { authorization: `Bearer ${accessToken}` },
  });
};

export const getUsers = async (accessToken) => {
  console.log("hereee");
  //   const response = await axios.get('/user');
  const response = await axios.get("/dev/all", {
    headers: { authorization: `Bearer ${accessToken}` },
  });

  console.log("userss", response);
  return response.data;
};
