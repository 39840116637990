import React, { useContext, useState } from "react";
import {
  Avatar,
  Box,
  Container,
  Paper,
  TextField,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Typography,
  Dialog,
  DialogContent,
} from "@mui/material";
import { Send as SendIcon } from "@mui/icons-material";
import { timesheetColumns } from "./utils";

import { EditableTable } from "../../../../../../../../components/Tables/NewEditableTable";
import {
  useCreateTimesheet,
  useDeleteTimesheet,
  useGetTimesheets,
  useUpdateTimesheet,
} from "./hooks";
import { validateTimesheet } from "../utils";
import { minimalMuiTableProps } from "../../../../../../../../styles/muiProps2";
import { useMsal } from "@azure/msal-react";

const initialData = {
  date: "",
  work_done: "",
  hours: 0,
  progress: 0,
};
const TimesheetsDialog = ({ open, handleClose, task }) => {
  const [validationErrors, setValidationErrors] = useState({});

  const { accounts } = useMsal();
  const username = accounts[0]?.name;

  const hooks = {
    useCreateItem: useCreateTimesheet,
    useGetItems: useGetTimesheets,
    useUpdateItem: useUpdateTimesheet,
    useDeleteItem: useDeleteTimesheet,
  };
  const actions = {
    validateItem: validateTimesheet,
  };
  const additionalProps = {
    ...minimalMuiTableProps,
    // initialState: { columnVisibility: { id: false } }
  };

  console.log(task);
  const taskMember = task?.task_members?.includes(username);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      padding={2}
      fullWidth
      maxWidth={"lg"}
    >
      <DialogContent>
        <Typography variant="h5" gutterBottom>
          Timesheets for Task: {task?.task || ""}
        </Typography>
        <EditableTable
          columns={timesheetColumns(
            username,
            validationErrors,
            setValidationErrors
          )}
          name="Timesheet"
          actions={actions}
          hooks={hooks}
          editMode="row"
          enableEditing={true}
          enableCreate={true}
          enableDelete={taskMember}
          additionalProps={additionalProps}
          initialData={initialData}
        />
      </DialogContent>
    </Dialog>
  );
};

export default TimesheetsDialog;
