export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  maxHeight: '70%',
  backgroundColor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  py: 2,
  px: 4,
  overflow: 'auto',
};
