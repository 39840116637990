import React from "react";

import GeneralTab from "./Tabs/GeneralTab";
import AccordionGroup from "../../../../../../components/AccordionGroup";
import Table from "../../../../../../components/Tables/BasicEditableTable";
import { EditableTable } from "../../../../../../components/Tables/NewEditableTable";
import { Notes, definitions, itpProcedures } from "./utils";

import { itpColumns } from "./cols";
import {
  finalPreparationMockData,
  hydrostaticTestMockData,
  manufacturingMockData,
  preFabMockData,
  pwhtMockData,
} from "./data.test";
import ITPRowsTab from "./Tabs/ITPRowsTab";
import { SelectRev } from "./Tabs/Components/SelectRev";
import { Box, Typography } from "@mui/material";
import {
  useCreateItp,
  useDeleteItp,
  // useGetItps,
  useGetItpsByProjectAndType,
  useUpdateItp,
} from "./hooks";

const itpKeys = [
  "GENERAL",
  "PRE-FABRICATION",
  "MANUFACTURING & NDE",
  "PWHT & NDE POST PWHT",
  "HYDROSTATIC TEST",
  "FINAL PREPARATION",
  "STANDARD PROCEDURES",
  "PROJECT SPECIFICATION",
  "NOTES",
  "INSPECTOR FUNCTION DEFINITION",
];
const groupDataByType = (data) => ({
  preFabData: data.filter((item) => item.type === "PRE-FABRICATION"),
  manufacturingData: data.filter((item) => item.type === "MANUFACTURING & NDE"),
  pwhtData: data.filter((item) => item.type === "PWHT & NDE POST PWHT"),
  hydrostaticTestData: data.filter((item) => item.type === "HYDROSTATIC TEST"),
  finalPreparationData: data.filter(
    (item) => item.type === "FINAL PREPARATION"
  ),
});

const revs = ["Rev-00", "Rev-01"];

export default function ITPTable() {
  const [itpData, setItpData] = React.useState({
    preFabData: preFabMockData,
    manufacturingData: manufacturingMockData,
    pwhtData: pwhtMockData,
    hydrostaticTestData: hydrostaticTestMockData,
    finalPreparationData: finalPreparationMockData,
  });

  const [rev, setRev] = React.useState(revs[0]);
  // const { data, isPending } = useGetItps();
  // console.log("itp", data);

  const handleRevChange = (event) => {
    setRev(event.target.value);
  };

  const itpPanels = [
    <GeneralTab generalData={{ rev }} />,
    <EditableTable
      name={"PRE-FABRICATION"}
      columns={itpColumns}
      hooks={{
        useCreateItem: useCreateItp,
        useGetItems: useGetItpsByProjectAndType.bind(null, "PRE-FABRICATION"),
        useUpdateItem: useUpdateItp,
        useDeleteItem: useDeleteItp,
      }}
      enableCreate={true}
      enableEditing={true}
      enableArchive={true}
      // data={[]}
    />,
    <EditableTable
      name={"MANUFACTURING & NDE"}
      columns={itpColumns}
      hooks={{
        useCreateItem: useCreateItp,
        useGetItems: useGetItpsByProjectAndType.bind(
          null,
          "MANUFACTURING & NDE"
        ),
        useUpdateItem: useUpdateItp,
        useDeleteItem: useDeleteItp,
      }}
      enableCreate={true}
      enableEditing={true}
      enableArchive={true}
      // data={[]}
    />,
    <EditableTable
      name={"PWHT & NDE POST PWHT"}
      columns={itpColumns}
      hooks={{
        useCreateItem: useCreateItp,
        useGetItems: useGetItpsByProjectAndType.bind(
          null,
          "PWHT & NDE POST PWHT"
        ),
        useUpdateItem: useUpdateItp,
        useDeleteItem: useDeleteItp,
      }}
      enableCreate={true}
      enableEditing={true}
      enableArchive={true}
      // data={[]}
    />,
    <EditableTable
      name={"HYDROSTATIC TEST"}
      columns={itpColumns}
      hooks={{
        useCreateItem: useCreateItp,
        useGetItems: useGetItpsByProjectAndType.bind(null, "HYDROSTATIC TEST"),
        useUpdateItem: useUpdateItp,
        useDeleteItem: useDeleteItp,
      }}
      enableCreate={true}
      enableEditing={true}
      enableArchive={true}
      // data={[]}
    />,
    <EditableTable
      name={"FINAL PREPARATION"}
      columns={itpColumns}
      hooks={{
        useCreateItem: useCreateItp,
        useGetItems: useGetItpsByProjectAndType.bind(null, "FINAL PREPARATION"),
        useUpdateItem: useUpdateItp,
        useDeleteItem: useDeleteItp,
      }}
      enableCreate={true}
      enableEditing={true}
      enableArchive={true}
      // data={[]}
    />,
    // <Table columns={itpColumns} data={itpData.manufacturingData} />,
    // <Table columns={itpColumns} data={itpData.pwhtData} />,
    // <Table columns={itpColumns} data={itpData.hydrostaticTestData} />,
    // <Table columns={itpColumns} data={itpData.finalPreparationData} />,
    <ITPRowsTab rows={itpProcedures} />,
    "PROJECT SPECIFICATION",
    <ITPRowsTab rows={Notes} />,
    <ITPRowsTab rows={definitions} />,
  ];
  return (
    <AccordionGroup
      keys={itpKeys}
      panels={itpPanels}
      //   defaultExpandedArray={[itpKeys[1]]}
      keysAsComponents={{
        GENERAL: (
          <Box sx={{ m: 0 }}>
            <Box sx={{ display: "inline-block" }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  pr: 2,
                  transform: "translateY(8px)",
                }}
              >
                {" "}
                GENERAL{" "}
              </Typography>
            </Box>

            <SelectRev
              rev={rev}
              handleRevChange={handleRevChange}
              revs={revs}
            />
          </Box>
        ),
      }}
    />
  );
}
