import { Box, Paper } from "@mui/material";
import React, { useEffect } from "react";
import SharepointTab from "./SharepointTab";
import NavTabs from "../../../../../components/Tabs/NavTabs";
import { useParams } from "react-router-dom";
import ChangeOrder from "./ChangeOrder";
import DocumentTransmittalTab from "./DocumentTransmittalTab";
import DocReviewer from "./DocReviewer";
import ProjectTasksPage from "../../../Project/allProjectsPage/ProjectsTable/ProjectTasksPage";
import { useDCS } from "./Table/DCSContext";

const DCSTab = (props) => {
  const { projectId } = useParams();
  const { documentList, getAllList } = useDCS();
  useEffect(() => {
    getAllList();
  }, []);

  console.log({ projectId });
  const tabs = [
    {
      label: "Sharepoint ",
      panel: <SharepointTab />,
    },
    // {
    //   label: "MRQ",
    //   panel: <DCSTable projectId={projectId || ""} type="MRQ" />,
    // },
    {
      label: "Doc. Reviewer",
      panel: <DocReviewer projectId={projectId} />,
    },
    {
      label: "Tasks",
      panel: <ProjectTasksPage catList={documentList} />,
    },
    // {
    //   label: "Doc. Transmittal",
    //   panel: (
    //     <DocumentTransmittalTab
    //       projectId={projectId || ""}
    //       type="Doc. Transmittal"
    //     />
    //   ),
    // },
    // {
    //   label: "RFI",
    //   panel: <DCSTable type="RFI" />,
    // },
    // {
    //   label: "Change Order",
    //   panel: <ChangeOrder type="Change Order" />,
    // },
    // {
    //   label: "Transmittal",
    //   panel: <DocumentTransmittalTab />,
    // },
  ];

  return (
    <Paper sx={{ width: "100%", display: "flex" }}>
      <NavTabs tabs={tabs} navLabel="dcs-tab" />
    </Paper>
  );
};

export default DCSTab;
