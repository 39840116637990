import { Box, Grid } from "@mui/material";
import React from "react";
import DrawingSelection from "../Components/DrawingSelection";
import TabControl from "./components/TabControl";
import ConsolidateModal from "./components/ConsolidateModal";
import DrawingBomTable from "./components/DrawingBomTable/DrawingBomTable";
import { DrawingBomContext } from "./DrawingBomContext";
import AddSrvModal from "./components/AddSrvModal";
import { useMcsContext } from "../MCS.context";
import LabelledBox from "../../../../../../components/widgets/LabelledBox";

export default function Main() {
  const { selectedDrawings, handleAddingNewDrawing } =
    React.useContext(DrawingBomContext);

  const { setSelectedDrawings } = useMcsContext();

  React.useEffect(() => {
    setSelectedDrawings([]);
  }, []);

  return (
    <Box>
      <Grid container sx={{}}>
        <Grid item xs={12} xl={6}>
          <LabelledBox sx={{ ml: 5 }}>
            <DrawingSelection
              selectedDrawings={selectedDrawings}
              handleAddingNewDrawing={handleAddingNewDrawing}
            />
          </LabelledBox>
        </Grid>

        <Grid
          item
          xs={12}
          xl={6}
          sx={{
            display: "flex",
            flexDirection: { xs: "row", xl: "row-reverse" },
          }}
        >
          <LabelledBox
            flexDirection="row-reverse"
            sx={{
              py: { xs: 1.5, xl: 3 },
              ml: { xs: 5, xl: 0 },
              mr: { xs: 0, xl: 5 },
            }}
          >
            {/* <Box>
              <SelectMaterialType />
            </Box> */}
            <TabControl />

            <ConsolidateModal />
            <AddSrvModal />
          </LabelledBox>
        </Grid>
      </Grid>

      <Box sx={{ mx: 5 }}>
        <DrawingBomTable />
      </Box>
    </Box>
  );
}
