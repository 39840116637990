export const getObject = ({ consolidatedMaterials }) => {
  const obj = {};

  consolidatedMaterials.forEach((m) => {
    if (m.tagNumber) {
      const previousState = obj[m.description];
      if (previousState?.description) {
        obj[m.description] = {
          ...previousState,
          quantity: Number(previousState.quantity) + Number(m.quantity),
        };
      } else {
        obj[m.description] = { ...m };
      }
    } else if (m.materialCode) {
      const previousState = obj[m.materialCode];

      if (previousState?.description) {
        obj[m.materialCode] = {
          ...previousState,
          // materialsIds: [...previousState.materialsIds, m.id],
          softwareId: m.softwareId,
          item: previousState.item + ', ' + m.item,
          markNo: previousState.markNo + ', ' + m.markNo,
          description: m.description,
          quantity: Number(previousState.quantity) + Number(m.quantity),
          totalWtInKgs:
            Math.round(
              (Number(previousState.totalWtInKgs) + Number(m.totalWtInKgs)) *
                100,
            ) / 100,
        };
      } else {
        obj[m.materialCode] = { ...m };
      }
    } else {
      const previousState = { ...obj[m.description], materials: [] };

      let quantity, unit;
      if (m.length) {
        // console.log({ len: m.length });
        unit = m.length.split(' ').pop();

        quantity = m.length.match(/\d+\.\d+/)[0];
        // console.log({ quantity, unit });

        m = { ...m, unit, quantity };
      } else {
        m = { ...m, unit: 'No.' };
      }

      if (previousState?.description) {
        obj[m.description] = {
          ...previousState,
          materials: [...previousState.materials, m],
          softwareId: m.softwareId,
          item: previousState.item + ', ' + m.item,

          description: m.description,
          quantity: Number(previousState.quantity) + Number(m.quantity),
          unit: m.unit,
        };
      } else {
        obj[m.description] = {
          ...m,
          quantity: m.quantity,
          unit: m.unit,
          materials: [...previousState.materials, m],
        };
      }
    }
  });

  return obj;
};
