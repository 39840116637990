import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useMaterialReactTable,
  MaterialReactTable,
  MRT_EditActionButtons,
} from "material-react-table";
import AddIcon from "@mui/icons-material/Add";
import ArchiveIcon from "@mui/icons-material/Archive";

// import { minimalMuiTableProps } from "../../../common/style/muiProps";
import { useParams, useNavigate } from "react-router-dom";
import CreateProjectDialog from "./CreateProjectModal";
import { useMsal } from "@azure/msal-react";
import { AuthContext } from "../../../../../context/authContext";

export const EditableTable = ({
  name,
  columns,
  displayMode,
  actions,
  api,
  enableEditing,
  enableCreate,
  initialData,
  additionalProps,
  parentId,
}) => {
  const [validationErrors, setValidationErrors] = useState({});
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const user = useMsal().accounts[0].name;
  const { accessToken } = useContext(AuthContext);

  useEffect(() => {
    console.log(user, accessToken);

    const fetchData = async () => {
      try {
        const items = await api.getItems(parentId, accessToken);
        setData(items);
        setIsLoading(false);
      } catch (error) {
        setIsError(true);
        console.error("Error fetching data:", error);
      }
    };

    accessToken && fetchData();
  }, [api, parentId, refresh, accessToken]);

  const navigate = useNavigate();

  const handleCreateItem = async (values) => {
    console.log(values, process.env.CLIENT_NAME);
    await api.createItem(parentId, values, accessToken);
    setRefresh((prev) => !prev);
  };

  const handleSaveItem = async ({ values, table }) => {
    try {
      setValidationErrors({});
      await api.updateItem(values, accessToken);
      table.setEditingRow(null);
      setRefresh((prev) => !prev);
    } catch (error) {
      console.error("Error saving item:", error);
    }
  };

  const openArchiveConfirmModal = (row) => {
    if (window.confirm("Are you sure you want to archive this item?")) {
      // api.deleteItem(row.original.id);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: data || [],
    createDisplayMode: displayMode,
    editDisplayMode: displayMode,
    enableEditing: true,
    getRowId: (row) => row.id,
    onEditingRowSave: handleSaveItem,
    defaultColumn: {
      minSize: 20,
    },
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex" }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Archive">
          <IconButton
            color="primary"
            onClick={() => openArchiveConfirmModal(row)}
          >
            <ArchiveIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        sx={{ display: enableCreate ? "flex" : "none" }}
        startIcon={<AddIcon />}
        onClick={() => setIsDialogOpen(true)}
      >
        Add {name}
      </Button>
    ),
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Create New {name}</DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h5">Edit {name}</DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    // ...minimalMuiTableProps,
    ...additionalProps,
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    positionGlobalFilter: "left",
    initialState: {
      pagination: { pageSize: 10, pageIndex: 0 },
      showGlobalFilter: false,
      columnVisibility: { id: false },
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 15],
      variant: "outlined",
    },
    positionActionsColumn: "last",
    state: {
      isLoading,
      isSaving: false,
      showAlertBanner: isError,
      showProgressBars: false,
    },
    muiTableBodyRowProps: ({ isDetailPanel, row, table }) => ({
      onClick: (event) => {
        const element = event.target;
        if (element.classList.contains("MuiTableCell-root")) {
          navigate(`/projects/${row.id}/equipments/main_tank`);
        }
      },
      sx: {
        cursor: "pointer",
      },
    }),
  });

  return (
    <>
      <MaterialReactTable table={table} />
      <CreateProjectDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSave={handleCreateItem}
      />
    </>
  );
};
