import { createContext, useEffect, useState } from "react";
import axios from "../api/axios";
// import {setSessionCookie, getSessionCookie} from "./cookieContext"
import { useLocation } from "react-router-dom";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  // const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")) || null)

  const [auth, setAuth] = useState({});
  const [accessToken, setAccessToken] = useState(() => {
    return localStorage.getItem("accessToken") || null;
  });
  const login = async (token) => {
    try {
      // const response = await axios.post("/dev/login", inputs);
      // const { role, username, token } = response.data;
      setAuth({ role: "admin" });
      setAccessToken(token); // Save to state and storage
      localStorage.setItem("accessToken", token);
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const logout = async () => {
    try {
      // await axios.post("/dev/logout");
      setAuth({});
      setAccessToken(null);
      localStorage.removeItem("accessToken");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  // useEffect(() => {
  //     localStorage.setItem("user", JSON.stringify(currentUser))
  // }, [currentUser]);

  return (
    <AuthContext.Provider
      value={{ auth, setAuth, login, logout, accessToken, setAccessToken }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// import { createContext, useEffect, useState } from "react"
// import axios from "axios"
// import { setSessionCookie, getSessionCookie } from "./cookieContext"
// // import {createBrowserHistory} from "history"
// import { useLocation } from 'react-router-dom'
// import Cookies from "js-cookie";

// export const AuthContext = createContext(getSessionCookie())

// export const AuthContextProvider = ({ children }) => {
//     // const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")) || null)
//     const [session, setSession] = useState(getSessionCookie())
//     useEffect(
//         () => {
//             //console.log("Event ran")
//             setSession(getSessionCookie());
//         },
//         [session]
//     );

//     // const [currentUser, setCurrentUser] = useState(null)
//     // //console.log(currentUser.username)

//     const login = async (inputs) => {
//         // const res = await axios.post("/dev/login", inputs)
//         // setCurrentUser(res.data.user_data);
//         const res = await axios.post("/dev/login", inputs)
//             .then((response) => {
//                 //console.log(response.data.user_data)
//                 setSessionCookie(response.data.user_data)
//             })
//     }

//     // //console.log(currentUser)
//     const logout = async () => {
//         await axios.post("/dev/logout")
//         // setCurrentUser(null);
//         Cookies.remove("session")

//     }

//     // useEffect(() => {
//     //     localStorage.setItem("user", JSON.stringify(currentUser))
//     // }, [currentUser]);

//     // return <AuthContext.Provider value={{ currentUser, login, logout }}>
//     return <AuthContext.Provider value={{ session, login, logout }}>
//         {children}
//     </AuthContext.Provider>;
// }
