import { MenuItem } from '@mui/material';
import {
  materialsOptions,
  plateSizeOptions,
  requirementsOptions,
} from './GenerateTable/utils';
import { drawingMcsDataCols } from '../../../Components/cols/drawingMaterialsMcsDataCols';

export const groupingPlatesTableCols = [
  { accessorKey: 'item', header: 'Item', size: 50 },
  { accessorKey: 'markNo', header: 'Mark', size: 50 },
  {
    accessorKey: 'quantity',
    header: 'Qty',
    enableEditing: false,
    size: 50,
  },
  {
    accessorKey: 'totalWtInKgs',
    header: 'Total Wt',
    size: 50,
  },
  { accessorKey: 'materialNo', header: 'Material', size: 70 },
  { accessorKey: 'materialCode', header: 'Material Code', size: 70 },
];

export const bulkedMaterialsDetailsCols = [
  { accessorKey: 'item', header: 'Item', size: 50 },
  { accessorKey: 'markNo', header: 'Mark', size: 50 },
  {
    accessorKey: 'quantity',
    header: 'Qty',
    enableEditing: false,
    size: 50,
  },
  { accessorKey: 'totalWtInKgs', header: 'Total Wt', size: 50 },
  { accessorKey: 'materialNo', header: 'Material', size: 70 },
  { accessorKey: 'materialCode', header: 'Material Code', size: 70 },
  { accessorKey: 'description', header: 'Description', size: 150 },
];

export const bulkedMaterialsCols = [
  ...bulkedMaterialsDetailsCols,
  ...drawingMcsDataCols,
];

export const SelectedTaggedMaterialsDetailsCols = [
  {
    header: 'Tag Number',
    accessorKey: 'tagNumber',
    enableEditing: false,
    size: 100,
  },
  {
    header: 'Description',
    accessorKey: 'description',
    enableEditing: false,
    size: 200,
  },
  {
    header: 'Quantity',
    accessorKey: 'quantity',
    enableEditing: false,
    size: 50,
  },
  {
    header: 'Remarks',
    accessorKey: 'remarks',
    enableEditing: false,
    size: 200,
  },
];

export const taggedMaterialsCols = [
  {
    header: 'Tag Number',
    accessorKey: 'taggedMaterial.tagNumber',
    enableEditing: false,
    size: 50,
  },
  {
    header: 'Description',
    accessorKey: 'description',
    enableEditing: false,
    size: 200,
  },

  ...drawingMcsDataCols,
];

export const GenerateTableCols = [
  {
    accessorKey: 'size',
    header: 'Plate Size',
    size: 20,
    muiEditTextFieldProps: ({ row }) => ({
      children: plateSizeOptions
        .find((opt) => opt.value === '16')
        .size.map((func) => (
          <MenuItem key={func} value={func}>
            {func}
          </MenuItem>
        )),
      select: true,
    }),
  },
  {
    accessorKey: 'thickness',
    header: 'Thk (mm)',
    // header: (
    //   <div>
    //     <p>Thk</p>
    //     <p>(mm)</p>
    //   </div>
    // ),
    size: 10,
  },
  {
    accessorKey: 'materialNo',
    header: 'Material',
    size: 50,
    muiEditTextFieldProps: () => ({
      children: materialsOptions
        .map((opt) => opt.material)
        .map((func) => (
          <MenuItem key={func} value={func}>
            {func}
          </MenuItem>
        )),
      select: true,
    }),
  },
  {
    accessorKey: 'requirements',
    header: 'Requirements',
    size: 30,
    muiEditTextFieldProps: () => ({
      children: requirementsOptions
        .map((opt) => opt.req)
        .map((func) => (
          <MenuItem key={func} value={func}>
            {func}
          </MenuItem>
        )),
      select: true,
    }),
  },
  {
    accessorKey: 'quantity',
    header: 'Qty',
    size: 50,
  },
  {
    accessorKey: 'description',
    header: 'Description',
    size: 70,
    enableEditing: false,
  },
  {
    accessorKey: 'materialCode',
    header: 'Material Code',
    size: 40,
    enableEditing: false,
  },
  {
    accessorKey: 'totalWeight',
    header: 'Total Weight',
    size: 40,
    enableEditing: false,
  },
  { accessorKey: 'diff', header: 'Diff', size: 50, enableEditing: false },
];
