import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { DrawingBomContext } from "../../DrawingBomContext";
import AddEntityForm from "../../../Components/Forms/AddEntityForm/index.jsx";
import DrawingsMaterialsTable from "../../../Components/Tables/DrawingsMaterialsTable";
import { modalStyle } from "../../../../../../../../components/modalStyle.js";

export default function AddSrvModal() {
  const { openAddSrvModal, handleCloseAddSrvModal, consolidatedMaterials } =
    React.useContext(DrawingBomContext);

  return (
    <div>
      <Modal open={openAddSrvModal} onClose={handleCloseAddSrvModal}>
        <Box sx={modalStyle}>
          <AddEntityForm
            entityType="srv"
            table={<DrawingsMaterialsTable materials={consolidatedMaterials} />}
            drawingMaterials={consolidatedMaterials}
            onCloseModal={handleCloseAddSrvModal}
          />
        </Box>
      </Modal>
    </div>
  );
}
