import React from 'react';
import { DrawingBomProvider } from './DrawingBomContext';
import Main from './Main';

export default function DrawingBomTab() {
  return (
    <DrawingBomProvider>
      <Main />
    </DrawingBomProvider>
  );
}
