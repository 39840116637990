import React from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { useMcsContext } from "../../../MCS.context";
import { secondAxios } from "../../../../../../../../api/axios";

export default function SelectFromExistedEntities({
  selectedCurrentAction,
  onSelectEntity,
  actionRadioBtnsData,
  onClickRadioBtn,
  currentEntity,
}) {
  const { project, setProject } = useMcsContext();

  React.useEffect(() => {
    async function getEntities() {
      const entities = await secondAxios.get(
        currentEntity + "?projectId=" + project.id
      );
      // console.log({ entities });

      setProject({ ...project, [currentEntity + "s"]: entities.data });
    }
    getEntities();
  }, []);

  const CurrentRadioForm = () => {
    switch (currentEntity) {
      case "po":
        return project[currentEntity + "s"].map((po) => {
          return (
            <FormControlLabel
              checked={actionRadioBtnsData.number === po.poNumber}
              value={po.poNumber}
              control={<Radio />}
              label={po.poNumber}
              onClick={onClickRadioBtn}
              key={po.id}
            />
          );
        });
      case "mrv":
        return project[currentEntity + "s"].map((mrv) => {
          return (
            <FormControlLabel
              checked={actionRadioBtnsData.number === mrv.mrvNumber}
              value={mrv.mrvNumber}
              control={<Radio />}
              label={mrv.mrvNumber}
              onClick={onClickRadioBtn}
              key={mrv.id}
            />
          );
        });

      case "srv":
        return project[currentEntity + "s"].map((srv) => {
          return (
            <FormControlLabel
              checked={actionRadioBtnsData.number === srv.srvNumber}
              value={srv.srvNumber}
              control={<Radio />}
              label={srv.srvNumber}
              onClick={onClickRadioBtn}
              key={srv.id}
            />
          );
        });
      default:
        return <></>;
    }
  };

  return (
    <>
      <FormControl
        sx={{
          width: "100%",
          maxHeight: "180px",
          overflow: "auto",
        }}
      >
        <RadioGroup
          // value={selectedCurrentAction}
          // onChange={onSelectEntity}
          sx={{ pl: 2 }}
        >
          {project[currentEntity + "s"][0] ? (
            <>
              <CurrentRadioForm />
            </>
          ) : (
            <p
              style={{
                color: "#bbb",
                fontSize: "14px",
                textAlign: "center",
                padding: 3,
              }}
            >
              {"No " + currentEntity.toUpperCase() + "s Found yet."}
            </p>
          )}
        </RadioGroup>
      </FormControl>
    </>
  );
}
