import React from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
export default function AllocateToDrawingMaterialsComponent({
  mrqMaterials,
  setMrqMaterials,
  selectedDrawingMaterials,
  setSelectedDrawingMaterials,
  disabled,
}) {
  const drawingMaterials = [];

  mrqMaterials.forEach((mm) => {
    drawingMaterials.push(...mm.materials);
  });
  const onAllocate = (e) => {
    const checked = e.target.checked;
    const id = e.target.value;

    if (checked) {
      setSelectedDrawingMaterials([
        ...selectedDrawingMaterials,
        drawingMaterials.find((dm) => dm.id === id),
      ]);
    } else {
      setSelectedDrawingMaterials(
        selectedDrawingMaterials.filter((dm) => dm.id !== id),
      );
    }
    console.log({
      e: e.target.value,
      checked: e.target.checked,
      selectedDrawingMaterials,
    });
  };

  const onSelectAll = (isSelectAll, rowId) => {
    const rowDrawingMaterials = mrqMaterials.find(
      (m) => m.id === rowId,
    ).materials;

    if (isSelectAll) {
      setSelectedDrawingMaterials(
        selectedDrawingMaterials.filter((sdm) => {
          const isExisted = rowDrawingMaterials
            .map((rdm) => rdm.id)
            .includes(sdm.id);
          console.log({ isExisted });
          return isExisted ? false : true;
        }),
      );

      setSelectedDrawingMaterials([
        ...selectedDrawingMaterials,
        ...rowDrawingMaterials,
      ]);
    } else {
      setSelectedDrawingMaterials(
        selectedDrawingMaterials.filter((sdm) =>
          rowDrawingMaterials.map((rdm) => rdm.id).includes(sdm.id),
        ),
      );
    }
  };

  return (
    <Box sx={{ maxHeight: 370, overflow: 'auto' }}>
      {mrqMaterials?.map((m) => {
        const drawingMaterials = m?.materials;

        return (
          <Row
            row={m}
            key={m.id}
            onAllocate={onAllocate}
            onSelectAll={onSelectAll}
            selectedDrawingMaterials={selectedDrawingMaterials.filter((sdm) =>
              drawingMaterials.map((dm) => dm.id).includes(sdm.id),
            )}
            mrqMaterials={mrqMaterials}
            setMrqMaterials={setMrqMaterials}
            disabled={disabled}
          />
        );
      })}
    </Box>
  );
}

function Row({
  row,
  onAllocate,
  selectedDrawingMaterials,
  onSelectAll,
  mrqMaterials,
  setMrqMaterials,
  disabled,
}) {
  //   console.log({ row });
  const drawingMaterials = row?.materials;

  const onChangeHeatNumber = (e) => {
    const heatNumber = e.target.value;

    setMrqMaterials(
      mrqMaterials.map((m) => {
        return m.id === row.id ? { ...m, heatNumber } : m;
      }),
    );
  };

  return (
    <Paper
      sx={{
        p: 1,
        m: 1,
        backgroundColor:
          selectedDrawingMaterials.length && 'hsla(250, 86%, 97%, 1)',
        borderRadius: '3px',
      }}
    >
      <Grid container>
        <Grid item xs={4} sx={{ px: 1 }}>
          <Typography sx={{ fontWeight: 'bold' }}>
            Tag Numbers: ( {selectedDrawingMaterials.length}/
            {drawingMaterials.length} )
          </Typography>

          <Box sx={{ maxHeight: 120, overflow: 'auto', mb: 0.5 }}>
            {drawingMaterials.map((dm) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      value={dm.id}
                      onChange={onAllocate}
                      disabled={disabled}
                      checked={selectedDrawingMaterials.find(
                        (sdm) => sdm.id === dm.id,
                      )}
                      defaultChecked={selectedDrawingMaterials.find(
                        (sdm) => sdm.id === dm.id,
                      )}
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                    />
                  }
                  label={dm.taggedMaterial?.tagNumber}
                  key={dm.id}
                />
              );
            })}
          </Box>

          {/* <Box sx={{}}>
            <Button
              size="small"
              variant="contained"
              startIcon={<DoneAllIcon />}
              fullWidth
              onClick={() => {
                onSelectAll(
                  selectedDrawingMaterials.length !== drawingMaterials.length,
                  row.id,
                );
              }}
            >
              {selectedDrawingMaterials.length === drawingMaterials.length
                ? 'Unallocate All'
                : 'Allocate All'}
            </Button>
          </Box> */}
        </Grid>
        <Grid item xs={8}>
          <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
            Description:
          </Typography>

          <Box sx={{ maxHeight: 80, overflow: 'auto', pl: 1 }}>
            {row.description}
          </Box>

          {selectedDrawingMaterials.length ? (
            <Box sx={{ pt: 1 }}>
              <TextField
                label="Heat Number"
                value={row.heatNumber}
                onChange={onChangeHeatNumber}
                fullWidth
                size="small"
                disabled={disabled}
              />
            </Box>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
