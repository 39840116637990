import { secondAxios } from "../../../../../../api/axios";

export const getTimesheets = async (raciId) => {
  // const response = await secondAxios.get('/timesheet/' + raciId);
  console.log("timesheet", raciId);

  const response = await secondAxios.get("/raci/" + raciId);
  return response.data?.timesheets;
};

export const createTimesheet = async (raciId, timesheet) => {
  console.log("dddddddd", timesheet);

  const postRequestData = {
    ...timesheet,
    hours: parseInt(timesheet.hours) || 0, //TODO: Check why MRT is returning strings though its set to number
    progress: parseInt(timesheet.progress) || 0,
    raciId,
  };
  console.log({ postRequestData });
  await secondAxios.post("/raci-timesheet", postRequestData);
};

export const updateTimesheet = async (timesheet) => {
  await secondAxios.put(`/raci-timesheet/${timesheet.id}`, timesheet);
};

export const deleteTimesheet = async (timesheetId) => {
  await secondAxios.delete(`/raci-timesheet/${timesheetId}`);
};
