import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React from "react";
import {
  bulkDescriptionMrqMaterialsCols,
  bulkMrqMaterialsCols,
  taggedMrqMaterialsCols,
} from "../cols/mrqMaterialsCols";
import { lightTable } from "../../../../../../../styles/muiProps";

export default function MrqMaterialsTable({ mrqMaterials, entity }) {
  const table = useMaterialReactTable({
    columns:
      mrqMaterials?.[0]?.tagNumber ||
      mrqMaterials?.[0]?.taggedMaterial?.tagNumber ||
      mrqMaterials?.[0]?.material?.taggedMaterial?.tagNumber ||
      mrqMaterials?.[0]?.materials?.[0]?.taggedMaterial?.tagNumber
        ? taggedMrqMaterialsCols
        : mrqMaterials?.[0]?.materialCode
        ? bulkMrqMaterialsCols
        : bulkDescriptionMrqMaterialsCols,

    data: mrqMaterials,

    ...lightTable,
    enableStickyHeader: true,

    muiTableContainerProps: { sx: { maxHeight: "350px" } },
  });

  return <MaterialReactTable table={table} />;
}
