export const drawingCols = [
  {
    label: 'Drawing Title',
    key: 'title',
  },
  {
    label: 'Drawing #',
    key: 'dwgNumber',
  },
  {
    label: 'Revision',
    key: 'revision',
  },
  {
    label: 'Sheet #',
    key: 'sheetNumber',
  },
  {
    label: 'Source',
    key: 'source',
  },
];
