import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import ArchiveIcon from "@mui/icons-material/Archive";

import {
  useMaterialReactTable,
  createRow,
  MRT_EditActionButtons,
  MaterialReactTable,
} from "material-react-table";

import { useParams } from "react-router-dom";
import { minimalMuiTableProps } from "../../styles/muiProps";
/**
 * EditableTable Component
 *
 * An Editable table with apis.
 * Allows creating, editing, and archiving items with optional validation.
 *
 * Props:
 * @param {string} name - The name of the table.
 * @param {Array} columns - The columns to display in the table.
 * @param {string} [displayMode] - The display mode for creating/editing rows ("row" | "modal" | "custom").
 * @param {Object} [actions] - Optional actions such as validation.
 * @param {Object} api - API methods for creating, fetching, updating, and deleting items.
 * @param {boolean} enableEditing - Flag to enable or disable editing.
 * @param {boolean} enableCreate - Flag to enable or disable item creation.
 * @param {Object} initialData - The initial data for a new row.
 * @param {Object} [additionalProps] - Additional props to pass to the table.
 * @param {string} parentId - The ID of the parent item.
 */

const EditableTable = ({
  name,
  columns,
  displayMode,
  actions,
  api,
  enableEditing,
  enableCreate,
  initialData,
  additionalProps,
  parentId,
}) => {
  const [validationErrors, setValidationErrors] = useState({});
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { id: equipId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const items = await api.getItems(parentId);
        console.log(name, items);
        setData(items);
        setIsLoading(false);
      } catch (error) {
        setIsError(true);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [api, refresh, parentId, name]);

  const handleCreateItem = async ({ values, table }) => {
    console.log("saving");
    console.log(table);

    try {
      let item = { ...values };
      if (equipId) {
        item = { ...values, projectId: equipId };
        // console.log({ equipId });
      }
      setValidationErrors({});
      console.log(item, parentId);
      await api.createItem(parentId, item);
      table.setCreatingRow(null);
      setRefresh((prev) => !prev);
    } catch (error) {
      console.error("Error creating item:", error);
    }
  };

  const handleSaveItem = async ({ values, table, row }) => {
    try {
      setValidationErrors({});
      console.log({ values }, row.original.id);
      console.log("updating");
      await api.updateItem({ ...values, id: row.original.id });
      table.setEditingRow(null);
      setRefresh((prev) => !prev);
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  const openArchiveConfirmModal = (row) => {
    if (window.confirm("Are you sure you want to archive this item?")) {
      // api.deleteItem(row.original.id);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: data || [],
    createDisplayMode: displayMode,
    editDisplayMode: displayMode,
    enableEditing: enableEditing,
    getRowId: (row) => row.id,
    onCreatingRowSave: handleCreateItem,
    onEditingRowSave: handleSaveItem,
    defaultColumn: {
      minSize: 20, //allow columns to get smaller than default
    },
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex" }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Archive">
          <IconButton
            color="primary"
            onClick={() => openArchiveConfirmModal(row)}
          >
            <ArchiveIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        sx={{ display: enableCreate ? "flex" : "none" }}
        disabled={!enableCreate}
        startIcon={<AddIcon />}
        onClick={() => {
          table.setCreatingRow(createRow(table, initialData));
        }}
      >
        Add {name}
      </Button>
    ),
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h6">Create New {name}</DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h5">Edit {name}</DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    ...minimalMuiTableProps,
    ...additionalProps,
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    positionGlobalFilter: "left",
    initialState: {
      pagination: { pageSize: 10, pageIndex: 0 },
      showGlobalFilter: false,
      columnVisibility: { id: false },
    },
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 15],
      variant: "outlined",
    },
    positionActionsColumn: "last",
    state: {
      isLoading: isLoading,
      isSaving: false,
      showAlertBanner: isError,
      showProgressBars: false,
    },
  });

  return <MaterialReactTable table={table} />;
};

export default EditableTable;
