export const MRQDetailsInputs = [
  {
    label: 'Project Name',
    field: 'projectName',
    grid: 0.8,
  },
  {
    label: 'Made By',
    field: 'drawnBy',
    grid: 0.6,
  },
  {
    label: 'Checked By',
    field: 'checkedBy',
    grid: 0.8,
  },
  {
    label: 'Date',
    field: 'mrqDate',
    grid: 0.5,
  },
  {
    label: 'MRQ Number',
    field: 'mrqNumber',
    grid: 0.8,
  },
  {
    label: 'MRQ Rev',
    field: 'rev',
    grid: 0.6,
  },
  {
    label: 'Title',
    field: 'title',
    grid: 0.8,
  },
];

// interface mrqMaterialsTableProps {
//     setEditedMaterials: React.Dispatch<React.SetStateAction<Record<string, Materials>>>
// }
