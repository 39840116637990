import { MRT_Row, useMaterialReactTable } from 'material-react-table';
import React, { useContext, useState } from 'react';
import Project from '../interfaces/projectInterface';
import projectsColumns from '../utils';
import { EditableTable } from './ProjectEditableTable';
import {
  createProject,
  deleteProject,
  getProjects,
  updateProject,
} from '../api';
import ProjectUser from '../interfaces/projectUserInterface';
import { paths } from '../projectsRoutes';
import { useNavigate } from 'react-router-dom';
import User from '../../UsersPage/interface/userInterface';
import ProjectMembersModal from './MembersNodal';
import project from '../../PunchlistPage/interfaces/taskInterface';
import { AuthContextMSAL } from '../../../common/contexts/mslAuthContext';

const initialData: Project = {
  id: '',
  name: '',
  users: [],
  progress: 0,
};

const ProjectTable = ({
  users,
  openViewerModal,
  openCommentsModal,
}: {
  users: User[];
  openViewerModal: (row: MRT_Row<Project>) => void;
  openCommentsModal: (row: MRT_Row<Project>) => void;
}) => {
  const [memberModalOpen, setMembersModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<string | null>(null);
  const { accessToken } = useContext(AuthContextMSAL);

  console.log('Accesss', accessToken);
  const navigate = useNavigate();
  const navigateToTaskList = (row: string) => {
    // get id of project here
    const id = row;
    navigate(`${paths.myProjects}/${id}`);
  };

  const additionalProps = {
    muiTableBodyRowProps: ({ row }: { row: MRT_Row<Project> }) => ({
      onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
        const target = event.target as Element;
        if (!target.closest('.MuiButtonBase-root') && !target.closest('a')) {
          // navigateToTaskList(row.id);
        }
      },
      sx: {
        // cursor: 'pointer', //change the cursor when adding an onClick
      },
    }),
    defaultColumn: {
      minSize: 20,
    },
  };

  const gotToMcs = (project: any) => {
    navigate(`/projects/${project?.id}/raci`);
  };

  const openMembersModal = (id: string | undefined) => {
    if (id) {
      setSelectedProject(id);
      setMembersModalOpen(true);
    }
  };
  // const users: ProjectUser[] = [{ id: "adgsdgwr", name: "John Doe", role: "lead" }]
  // const users = ["John Doe", "Jill SMith", "waagas"]
  return (
    <>
      <EditableTable<Project>
        name="Project"
        columns={projectsColumns(
          users,
          openViewerModal,
          gotToMcs,
          openMembersModal,
          openCommentsModal,
        )}
        initialData={initialData}
        enableEditing={true}
        enableCreate={true}
        displayMode="modal"
        api={{
          getItems: () => getProjects(accessToken),
          createItem: createProject,
          updateItem: updateProject,
          deleteItem: deleteProject,
        }}
        additionalProps={additionalProps}
        parentId="" //TODO: make parentID optional... or company could be parent of project
      />
      <ProjectMembersModal
        open={memberModalOpen}
        onClose={() => {
          setMembersModalOpen(false);
        }}
        projectId={selectedProject}
      />
    </>
  );
};

export default ProjectTable;
