import { Box, Modal } from "@mui/material";
import React from "react";
import AddEntityForm from "../../../Components/Forms/AddEntityForm";
import MrqMaterialsTable from "../../../Components/Tables/MrqMaterialsTable";
import { useMrqContext } from "../../MrqContext";
import { useMcsContext } from "../../../MCS.context";
import { modalStyle } from "../../../../../../../../components/modalStyle";

export default function AddPoModal() {
  const { selectedMrqMaterials } = useMcsContext();
  const { openAddPoModal, setOpenAddPoModal } = useMrqContext();

  const onCloseModal = () => {
    setOpenAddPoModal(false);
  };
  return (
    <Modal open={openAddPoModal} onClose={onCloseModal}>
      <Box sx={modalStyle}>
        <AddEntityForm
          entityType="po"
          table={<MrqMaterialsTable mrqMaterials={selectedMrqMaterials} />}
          mrqMaterials={selectedMrqMaterials}
          onCloseModal={onCloseModal}
        />
      </Box>
    </Modal>
  );
}
