import { MRT_Row, type MRT_ColumnDef } from 'material-react-table';
import {
  Autocomplete,
  Avatar,
  AvatarGroup,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
  menuClasses,
  selectClasses,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import AddIcon from '@mui/icons-material/Add';

import { useNavigate } from 'react-router-dom';
import icon from '../../../common/assets/icons/assym1.png';
import Project from './interfaces/projectInterface';
import { LinearProgressWithLabel } from '../../common/components/LinearProgressWithLabel';
import ProjectUser from './interfaces/projectUserInterface';
import { MouseEvent, SyntheticEvent, useEffect, useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ClearIcon from '@mui/icons-material/Clear';
import { deepPurple } from '@mui/material/colors';
import User from '../UsersPage/interface/userInterface';
import { TabUnselected } from '@mui/icons-material';

import sp_icon from '../../common/assets/images/sp_icon.png';
import autodesk_icon from '../../common/assets/images/autodesk.png';

import TaskOutlinedIcon from '@mui/icons-material/Task';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import DescriptionIcon from '@mui/icons-material/Description';
import ChatIcon from '@mui/icons-material/Chat';

const userRoles: string[] = ['Lead', 'Member'];

interface MemberItemProps {
  member: ProjectUser;
  handleChange: (event: SelectChangeEvent) => void;
  handleDelete: (event: MouseEvent) => void;
}

// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
// }));

const MemberItem = ({
  member,
  handleChange,
  handleDelete,
}: MemberItemProps) => (
  // <Box pt={1}>
  <Stack
    direction="row"
    sx={{ alignItems: 'center', justifyContent: 'space-between' }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <AccountCircleIcon color="primary" fontSize="large" />
      <Typography pl={1}>{member.name}</Typography>
    </Box>

    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <FormControl variant="standard" sx={{ m: 0.5, minWidth: 140 }}>
        <Select
          labelId="select-label"
          id="select"
          name={member.userId}
          value={member.role}
          disableUnderline
          variant="standard"
          size="small"
          onChange={handleChange}
          sx={{
            [`& .${selectClasses.select}`]: {
              background: 'white',
              color: 'grey',
              borderRadius: '4px',
              padding: '10px 24px',
              backgroundColor: '#F3F4F6FF',
              border: 'none',
              '&:focus': {
                borderRadius: '12px',
                backgroundColor: '#F3F4F6FF',
                background: 'white',
                borderColor: deepPurple[50],
                border: 'none',
              },
            },
            [`& .${selectClasses.icon}`]: {
              right: '12px',
            },
          }}
        >
          {userRoles.map((role, i) => (
            <MenuItem key={i} value={role}>
              {role}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ClearIcon color="primary" fontSize="small" />
    </Box>
  </Stack>
  // </Box>
);

const projectsColumns = (
  users: User[],
  openViewerModal: (row: MRT_Row<Project>) => void,
  gotToMcs: any,
  openMembersModal: (id: string | undefined) => void,
  openCommentsModal: (row: MRT_Row<Project>) => void,
): MRT_ColumnDef<Project>[] => [
  {
    accessorKey: 'id',
    header: 'Id',
    enableEditing: false,
    size: 80,
    Edit: () => <div></div>,
  },
  {
    accessorKey: 'projectName',
    header: 'Project Name',
    size: 500,
    muiEditTextFieldProps: ({ cell, row, table }) => ({
      required: true,
      // variant:"outlined",
      size: 'small',
      sx: {
        p: '10px 0px 0px 0px',
      },
      InputLabelProps: {
        shrink: true,
        sx: {
          fontWeight: 700,
          fontSize: '18px',
          lineHeight: '26px',
          pb: 2,
          color: 'black',
        },
      },
    }),
  },
  // {
  //   accessorKey: 'mcs',
  //   header: 'MCS',
  //   size: 40,
  //   enableEditing: false,
  //   Edit: () => null,
  //   enableColumnActions: false,
  //   Cell: ({ cell, row }) => {
  //     const name = row.original.name;

  //     return (
  //       <IconButton
  //         onClick={() => {
  //           gotToMcs(row.original);
  //         }}
  //       >
  //         <DescriptionIcon color="primary" />
  //       </IconButton>
  //     );
  //   },
  // },
  {
    accessorKey: 'link',
    header: 'DCS',
    size: 40,
    enableEditing: false,
    Edit: () => null,
    enableColumnActions: false,
    Cell: ({ cell, row }) => {
      const name = row.original.name;

      // For cbl
      // const dcsLink = "https://enerfacprojectscom.sharepoint.com/sites/CB-Lincoln/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FCB%2DLincoln%2FShared%20Documents%2F" + name

      // For cb
      const dcsLink = cell.getValue() as string;
      // console.log('dec link', row.original);

      return (
        <a href={dcsLink} target="_blank" rel="noopener noreferrer">
          <img src={sp_icon} height={'40px'} alt="SharePoint" />
        </a>
      );
    },
  },
  {
    accessorKey: 'raci',
    header: 'RACI',
    size: 40,
    enableEditing: false,
    enableColumnActions: false,
    Edit: () => null,
    Cell: ({ row, cell }) => {
      return (
        <a href={`/projects/${row.original.id}/raci`}>
          <AssignmentIndIcon color="primary" />
        </a>
      );
    },
  },
  {
    accessorKey: 'chat',
    header: 'Chat',
    size: 40,
    enableEditing: false,
    enableColumnActions: false,
    Edit: () => null,
    Cell: ({ row, cell }) => {
      return (
        <a
          target="_blank"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            openCommentsModal(row);
          }}
        >
          <ChatIcon color="primary" />
        </a>
      );
    },
  },
  {
    accessorKey: 'tasks',
    header: 'Tasks',
    size: 40,
    enableEditing: false,
    enableColumnActions: false,
    Edit: () => null,
    Cell: ({ row, cell }) => {
      return (
        <a href={`/projects/${row.original.id}/task`}>
          <TaskOutlinedIcon color="primary" />
        </a>
      );
    },
  },
  // {
  //   accessorKey: 'model',
  //   header: '3D',
  //   size: 40,
  //   enableEditing: false,
  //   Edit: () => null,
  //   enableColumnActions: false,
  //   Cell: ({ cell, row }) => {
  //     const name = row.original.name;
  //     const dcsLink =
  //       'https://enerfacprojectscom.sharepoint.com/sites/CB-Montreal/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FCB%2DMontreal%2FShared%20Documents/' +
  //       name;
  //     return (
  //       <a
  //         // target="_blank"
  //         style={{ cursor: 'pointer' }}
  //         onClick={() => {
  //           openViewerModal(row);
  //         }}
  //       >
  //         <img src={autodesk_icon} height={'30px'} alt="SharePoint" />
  //       </a>
  //     );
  //   },
  // },
  // {
  //   accessorKey: 'mrq',
  //   header: 'MRQ',
  //   size: 40,
  //   enableEditing: false,
  //   enableColumnActions: false,
  //   Edit: () => null,
  //   Cell: ({ row, cell }) => {
  //     return (
  //       <a href={`/projects/${row.original.id}/mrq`}>
  //         <RequestQuoteIcon color="primary" />
  //       </a>
  //     );
  //   },
  // },
  {
    accessorKey: 'members',
    header: 'Members',
    size: 150,
    Cell: ({ row, cell }) => {
      const members: any = cell.getValue();
      console.log('mmmmmm', members);

      const visibleMembers = members?.slice(0, 2);
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box>
            {visibleMembers?.map((member: any, index: any) => (
              <Chip key={index} label={member.user.username} size="small" />
            ))}
            {members?.length > 2 && (
              <Tooltip title={members.slice(2).join(', ')} arrow>
                <Chip label={`${members.length - 2}+`} size="small" />
              </Tooltip>
            )}
          </Box>
          <IconButton onClick={() => openMembersModal(row.original.id)}>
            <InfoIcon />
          </IconButton>
        </Box>
      );
    },
    // muiEditTextFieldProps: ({ value, onChange }) => ({
    //   children: users.map((user, index) => (
    //     <MenuItem key={index} value={user}>
    //       {user}
    //     </MenuItem>
    //   )),
    //   select: true,
    //   SelectProps: {
    //     multiple: true,
    //     renderValue: (selected:any) => (
    //       <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
    //         {selected.map((value:any, index:any) => (
    //           <Chip key={index} label={value} />
    //         ))}
    //       </Box>
    //     ),
    //   },
    // }),
  },
];

export default projectsColumns;
