import { secondAxios } from "../../../../../../api/axios";

export const getReconcileMaterials = async ({ parent, parentId }) => {
  console.log({ parent, parentId });
  const response = await secondAxios.get(`/material/${parent}/${parentId}`);

  return response.data;
};

export async function getAllProjectMaterials(projectId, setMaterials) {
  const materials = await getReconcileMaterials({
    parent: "project",
    parentId: projectId,
  });
  // console.log({ materials });
  setMaterials(materials);
}

export const getProjectPos = async (projectId) => {
  const response = await secondAxios.get(`/po?projectId=${projectId}`);
  console.log({ projectId, response });
  return response.data;
};

export const getPoMaterials = async (poId) => {
  const response = await secondAxios.get(`/po/${poId}`);

  return response.data;
};

export async function getAllProjectPos(projectId, setPos) {
  const pos = await getProjectPos(projectId);

  setPos(pos);
}
