import { TextField } from "@mui/material";
import React from "react";
import VendorSelector from "../../../BidTab/components/vendorSelector";
import LabelledBox from "../../../../../../../../components/widgets/LabelledBox";

export default function AddNewEntity({
  currentEntity,
  entityTextData,
  onTextChange,
}) {
  return (
    <>
      <TextField
        id="number"
        label={currentEntity.toUpperCase() + " Number"}
        name="number"
        value={entityTextData.number}
        onChange={onTextChange}
        variant="outlined"
        fullWidth
        autoComplete="off"
      />
      <TextField
        margin="dense"
        name="date"
        value={entityTextData.date}
        onChange={onTextChange}
        type="date"
        variant="outlined"
        fullWidth
      />
      {currentEntity === "po" && (
        <LabelledBox label="Vendors" sx={{ width: "100%" }}>
          <VendorSelector isMultiple={false} />
        </LabelledBox>
      )}
    </>
  );
}
