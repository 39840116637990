import { Box, Chip } from "@mui/material";
import React from "react";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ChipModal from "./Modal";
import { useMcsContext } from "../../MCS.context";
import { secondAxios } from "../../../../../../../api/axios";

const data = {
  mrq: [
    {
      number: "MRQ-001",
      date: "2024-11-18",
    },
  ],
  po: [
    {
      number: "PO-001",
      date: "2024-11-23",
    },
    {
      number: "PO-002",
      date: "2024-11-28",
    },
  ],
  mrv: [
    {
      number: "MRV-001",
      date: "2024-11-23",
    },
  ],
  srv: [
    {
      number: "SRV-001",
      date: "2024-11-23",
    },
    {
      number: "SRV-002",
      date: "2024-11-28",
    },
  ],
};

export default function ChipsTableCellComponent({ type, chips }) {
  const [open, setOpen] = React.useState(false);

  const { project, setProject } = useMcsContext();

  const [entity, setEntity] = React.useState({ srvMaterials: [] });

  const onClick = async (c) => {
    setEntity(c);
    // console.log({ c });

    const existedEntity = project[type + "s"].find((e) => e.id === c.id);
    // console.log({ existedEntity });
    existedEntity
      ? setEntity(existedEntity)
      : await secondAxios
          .get(type + "/" + c.id)
          .then((res) => {
            setEntity(res.data);

            // console.log('call entity by id: ', { data });
            setProject({
              ...project,
              [type + "s"]: [...project[type + "s"], res.data],
            });
          })
          .catch((e) => {
            console.log(e);
          });

    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <Box>
      {chips?.map((c, i) => {
        return c?.id ? (
          <Box key={i} sx={{ p: 0.2 }}>
            <Chip
              icon={<InsertDriveFileIcon />}
              label={
                c[type + "Number"] +
                (c[type + "Date"] ? " - " + c[type + "Date"] : "")
              }
              //   component="a"
              //   href="#basic-chip"
              //   clickable
              onClick={() => onClick(c)}
              variant="outlined"
            />
            <ChipModal
              type={type}
              entity={entity}
              open={open}
              handleClose={handleClose}
            />
          </Box>
        ) : (
          ""
        );
      })}{" "}
    </Box>
  );
}

// <Chip icon={<FaceIcon />} label="With Icon" />
// <Chip icon={<FaceIcon />} label="With Icon" variant="outlined" />
