import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React from "react";
import { bulkedMaterialsCols, taggedMaterialsCols } from "./cols";
import { DrawingBomContext } from "../../DrawingBomContext";
import { lightTable } from "../../../../../../../../styles/muiProps";

export default function MainSelectedMaterialsTable() {
  const { consolidatedMaterials } = React.useContext(DrawingBomContext);

  console.log({ consolidatedMaterials });

  const table = useMaterialReactTable({
    columns: consolidatedMaterials?.[0].tagNumber
      ? taggedMaterialsCols
      : bulkedMaterialsCols,
    data: consolidatedMaterials.map((m) => {
      return m.description
        ? m
        : {
            ...m,
            description: m.taggedMaterial?.component + m.taggedMaterial?.size,
          };
    }),
    ...lightTable,

    muiTableContainerProps: { sx: { maxHeight: "350px" } },
  });

  return <MaterialReactTable table={table} />;
}
