import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React from "react";
import { DrawingBomContext } from "../../DrawingBomContext";
import { groupingPlatesTableCols } from "./cols";
import {
  bulkDescriptionMrqMaterialsCols,
  taggedMrqMaterialsCols,
} from "../../../Components/cols/mrqMaterialsCols";
import { lightTable } from "../../../../../../../../styles/muiProps";

export default function GroupingTable() {
  const {
    consolidatedMaterials,
    consolidatedMaterialsData,
    consolidatedTaggedMaterialsData,
    consolidatedDescriptionMaterialsData,
  } = React.useContext(DrawingBomContext);

  const isTaggedMaterials = consolidatedMaterials?.[0].tagNumber;
  const isMaterialCodeMaterials = consolidatedMaterials?.[0].materialCode;

  // console.log({ consolidatedMaterials });
  const table = useMaterialReactTable({
    columns: isTaggedMaterials
      ? taggedMrqMaterialsCols
      : isMaterialCodeMaterials
      ? groupingPlatesTableCols
      : bulkDescriptionMrqMaterialsCols,

    data: isTaggedMaterials
      ? consolidatedTaggedMaterialsData.map((m) => {
          return m.description
            ? m
            : {
                ...m,
                description:
                  m.taggedMaterial?.component + m.taggedMaterial?.size,
              };
        })
      : isMaterialCodeMaterials
      ? consolidatedMaterialsData
      : consolidatedDescriptionMaterialsData,
    enableStickyHeader: true,
    ...lightTable,

    muiTableContainerProps: {
      sx: { maxHeight: isTaggedMaterials ? "350px" : "250px" },
    },
  });

  return <MaterialReactTable table={table} />;
}
