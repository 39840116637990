import ChipsTableCellComponent from '../ChipsTableCell.Component';

export const drawingMcsDataCols = [
  {
    accessorKey: 'mrqNumber',
    header: 'Mrq #',
    enableEditing: false,
    size: 70,
    Cell: ({ row }) => {
      const chips =
        row.original.mrqMaterials?.map((m) => {
          return m?.mrq;
        }) || [];
      return <ChipsTableCellComponent type="mrq" chips={chips} />;
    },
  },
  {
    accessorKey: 'poNumber',
    header: 'PO #',
    enableEditing: false,
    size: 70,
    Cell: ({ row }) => {
      let chips = [];

      row.original.mrqMaterials?.forEach((m) => {
        m.poMaterials?.forEach((pm) => chips.push(pm.po));
      });
      return <ChipsTableCellComponent type="po" chips={chips} />;
    },
  },
  {
    accessorKey: 'mrvNumber',
    header: 'MRV #',
    enableEditing: false,
    size: 70,
    Cell: ({ row }) => {
      const chips =
        row.original.mrvMaterials?.map((m) => {
          return m?.mrv;
        }) || [];
      return <ChipsTableCellComponent type="mrv" chips={chips} />;
    },
  },
  {
    accessorKey: 'srvNumber',
    header: 'SRV #',
    enableEditing: false,
    size: 70,
    Cell: ({ row }) => {
      const chips =
        row.original.srvMaterials?.map((m) => {
          return m?.srv;
        }) || [];
      return <ChipsTableCellComponent type="srv" chips={chips} />;
    },
  },
  {
    accessorKey: 'heatNumber',
    header: 'Heat #',
    enableEditing: false,
    size: 70,
  },

  {
    accessorKey: 'actions',
    header: 'Actions',
    enableEditing: false,
    size: 30,
  },
];
