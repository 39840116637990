import React from "react";
import POSelection from "../PoReconcile/POSelection";
import { Box, Button } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import AddMrvModal from "./AddMrvModal";
import PoDetails from "./PoDetails";

import { taggedMrqMaterialsCols } from "../Components/cols/mrqMaterialsCols";
import { mrqMaterialsMcsDataCols } from "../Components/cols/mrqMaterialsMcsCols";
import { useMcsContext } from "../MCS.context";
import { lightTable } from "../../../../../../styles/muiProps";
import LabelledBox from "../../../../../../components/widgets/LabelledBox";

export default function PoManger() {
  const { project } = useMcsContext();

  const [openAddMrvModal, setOpenAddMrvModal] = React.useState(false);
  const [selectedPoMaterials, setSelectedPoMaterials] = React.useState([]);

  const formattedData = (materials) => {
    return materials?.map((pm) => {
      // console.log({ pm });
      return {
        materials: pm.mrqMaterial.materials,
        description: pm.mrqMaterial?.description,
        quantity: pm.mrqMaterial?.quantity,
        mrvQuantity: pm.mrqMaterial?.quantity,
        mrvMrqMaterials: pm.mrqMaterial?.mrvMrqMaterials,
        heatNumber: pm.mrqMaterial?.heatNumber,
        id: [pm.mrqMaterial?.id],
      };
    });
  };

  const table = useMaterialReactTable({
    data: formattedData(project.selectedPo?.poMaterials) || [],
    columns: [
      ...taggedMrqMaterialsCols,
      ...mrqMaterialsMcsDataCols.filter(
        (c) => !["mrqNumber", "poNumber", "srvNumber"].includes(c.accessorKey)
      ),
    ],
    ...lightTable,
    enableTopToolbar: true, //overriding lightTable

    enableRowSelection: true,

    renderTopToolbarCustomActions: ({ table }) => {
      const selectedRows = table.getSelectedRowModel();

      const isDisabled = () => {
        if (selectedRows?.rows[0]) {
          return false;
        }
        return true;
      };
      return (
        <Box sx={{ display: "flex", gap: "1rem", p: "4px" }}>
          <AddMrvModal
            openAddMrvModal={openAddMrvModal}
            setOpenAddMrvModal={setOpenAddMrvModal}
            selectedPoMaterials={selectedPoMaterials}
            setSelectedPoMaterials={setSelectedPoMaterials}
          />
          <Button
            variant="contained"
            disabled={isDisabled()}
            onClick={() => {
              setOpenAddMrvModal(true);
              setSelectedPoMaterials(
                selectedRows.rows.map((r) => {
                  return { ...r.original, id: r.original.id[0] };
                })
              );
            }}
          >
            Add to MRV
          </Button>
        </Box>
      );
    },
  });
  return (
    <Box sx={{ ml: 3 }}>
      <Box>
        <LabelledBox label="PO Manager :">
          {/* sx={{ display: 'inline-block' }}> */}
          <POSelection from="poManager" />
        </LabelledBox>

        {project.selectedPo?.id && <PoDetails po={project.selectedPo} />}
      </Box>

      <MaterialReactTable table={table} />
    </Box>
  );
}
