import * as React from "react";
import { Box } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { DrawingBomContext } from "../../../DrawingBomContext";
import { drawingMaterialsCols } from "../../../../Components/cols/drawingsMaterialsCols";
import { drawingMcsDataCols } from "../../../../Components/cols/drawingMaterialsMcsDataCols";
import { lightTable } from "../../../../../../../../../styles/muiProps";

export default function MaterialsTable({ drawing }) {
  const { changeSelectionMaterials } = React.useContext(DrawingBomContext);

  // console.log({ dxpeMaterials: drawing.materials });

  const data = drawing.materials?.map((m) => {
    let quantity, unit;
    if (m.length) {
      // console.log({ len: m.length });
      unit = m.length.split(" ").pop();

      quantity = m.length.match(/\d+\.\d+/)[0];
      // console.log({ quantity, unit });

      m = { ...m, unit, quantity };
    } else {
      m = { ...m, unit: "No." };
    }

    return m.description
      ? m
      : {
          ...m,
          description: m?.taggedMaterial?.component + m?.taggedMaterial?.size,
        };
  });

  const table = useMaterialReactTable({
    data,

    columns: [
      ...drawingMaterialsCols(drawing?.materials),
      ...drawingMcsDataCols,
    ],

    enableRowSelection: true,
    ...lightTable,
    enableStickyHeader: true,
    muiTableContainerProps: { sx: { maxHeight: "400px" } },
    getRowId: (originalRow) => originalRow.id,
    // onRowSelectionChange: onRowSelection,
    // state: { rowSelection },
  });

  React.useEffect(() => {
    const selectedMaterialsIds = Object.keys(table.getState().rowSelection);

    const selectedMaterials = selectedMaterialsIds.map((id) => {
      return drawing.materials.find((m) => m.id === id);
    });
    const drawingSelectedMaterials = {};

    drawingSelectedMaterials[drawing.id] = selectedMaterials;

    changeSelectionMaterials(drawingSelectedMaterials);
  }, [table.getState().rowSelection]);

  return (
    <Box sx={{ maxHeight: 400, backgroundColor: "#fff" }}>
      <MaterialReactTable table={table} />
    </Box>
  );
}
