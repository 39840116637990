import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { queryClient } from "../../../../../../index";
import {
  getItps,
  getItpsByProjectAndType,
  createItp,
  updateItp,
  deleteItp,
} from "./api";
import { useParams } from "react-router-dom";

// export function useGetItps() {
//   return useQuery({
//     queryKey: ["itps"],
//     queryFn: getItps,
//   });
// }

export function useGetItpsByProjectAndType(type) {
  const { id: projectId } = useParams();
  //   console.log("ttttttttttttttttt");

  return useQuery({
    queryKey: ["itps", "project", projectId, type],
    queryFn: () => getItpsByProjectAndType(projectId, type),
    staleTime: 100000,
    enabled: !!projectId, // Ensure the query is only executed if projectId exists
  });
}

export function useCreateItp() {
  //   console.log("hook", data);
  const { id: projectId } = useParams();

  return useMutation({
    mutationFn: (data) =>
      createItp({ type: data.type, ...data.values, projectId }),
    onSuccess: () => {
      queryClient.invalidateQueries(["itps"]);
    },
  });
}

export function useUpdateItp() {
  return useMutation({
    mutationFn: ({ values }) => updateItp(values.id, { ...values }),
    onSuccess: () => {
      queryClient.invalidateQueries(["itps"]);
    },
  });
}

// Hook to delete an ITP
export function useDeleteItp() {
  return useMutation({
    mutationFn: (id) => deleteItp(id),
    onSuccess: () => {
      queryClient.invalidateQueries(["itps"]);
    },
  });
}
