import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createTask, deleteTask, getAllTasks, updateTask } from "./api";
import { useParams } from "react-router-dom";

//CREATE hook (post new task to api)
export function useCreateTask() {
  const queryClient = useQueryClient();
  const { id } = useParams();

  return useMutation({
    mutationFn: (task) => {
      return createTask({
        task: task.values,
        projectId: id,
        accessToken: task.accessToken,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["tasks", id] }),
  });
}

//READ hook (get tasks from api)
export function useGetTasks({ catList, accessToken }) {
  console.log("catListt", catList);
  const { id } = useParams();
  return useQuery({
    queryKey: ["tasks", id, catList],
    queryFn: () => getAllTasks({ projectId: id, accessToken, catList }),
    enabled: accessToken !== null,
    // enabled: projectId ? true : false,
    staleTime: 30000,
    cacheTime: 60000,
    keepPreviousData: true,
  });
}

//UPDATE hook (put task in api)
export function useUpdateTask() {
  const queryClient = useQueryClient();
  // const { projectId } = useParams()
  return useMutation({
    mutationFn: (task) =>
      updateTask({ task: task.values, accessToken: task.accessToken }),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["tasks"] }),
  });
}

//DELETE hook (delete task in api)
export function useDeleteTask() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteTask,
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["tasks"] }),
  });
}
