import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { MRQDetailsInputs } from "./utils";
import { DrawingBomContext } from "../../../DrawingBomContext";
import { MCSContext } from "../../../../MCS.context";
import LabelledBox from "../../../../../../../../../components/widgets/LabelledBox";
import SelectDoc from "./SelectDoc";
import { secondAxios } from "../../../../../../../../../api/axios";

export default function MrqForm() {
  const { project } = React.useContext(MCSContext);
  const { mrqDetails, onMrqDetailsChange, selectedMrqDoc, setSelectedMrqDoc } =
    React.useContext(DrawingBomContext);

  const [projectDocs, setProjectDocs] = React.useState([]);

  const [newDoc, setNewDoc] = React.useState("");

  React.useEffect(() => {
    async function getAllDocumentLists() {
      try {
        const res = await secondAxios.get(`documents/${project.id}/type/mrq`);
        if (res?.data) {
          setProjectDocs(res.data);
        }
      } catch (error) {
        console.log({ error });
      }
    }

    getAllDocumentLists();
  }, []);

  const onChangeNewDoc = (e) => {
    setSelectedMrqDoc("");
    setNewDoc(e.target.value);
  };
  // console.log("selectedMrqDoc: ", selectedMrqDoc);
  const onSelectExistedDoc = (event) => {
    setNewDoc("");
    setSelectedMrqDoc(event.target.value);
  };

  const onSaveNewDoc = async () => {
    try {
      const res = await secondAxios.post(`documents/${project.id}`, {
        type: "mrq",
        title: newDoc,
      });
      if (res?.data) {
        setProjectDocs([...projectDocs, res.data]);
        setNewDoc("");
        setSelectedMrqDoc(res.data);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <Box>
      <LabelledBox label="MRQ Details">
        <Grid
          container
          // spacing={2}
          columns={{ xs: 6.8 }}
          sx={{ width: "900px" }}
        >
          {MRQDetailsInputs.map((item, index) => (
            <React.Fragment key={index}>
              <Grid item xs={item.grid} sx={{ p: 0.5 }}>
                <Typography>{item.label}</Typography>
                {/* <Box sx={{ backgroundColor: "red" }}>{item.label}</Box> */}
              </Grid>
              {/* <Grid item xs={item.label === "From" || item.label === "Title" ? 2.6 : 1} > */}
              <Grid item xs={item.label === "Title" ? 2.6 : 1} sx={{ p: 0.5 }}>
                {/* <Typography color={"GrayText"}>Values</Typography> */}
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  hiddenLabel
                  size="small"
                  disabled={item.field === "projectName" ? true : false}
                  fullWidth
                  name={item.field}
                  type={item.field === "mrqDate" ? "date" : "text"}
                  autoComplete="off"
                  value={
                    item.field === "projectName"
                      ? project.name
                      : mrqDetails?.[item.field]
                  }
                  inputProps={{ sx: { padding: "2px", pl: "5px" } }}
                  onChange={onMrqDetailsChange}
                />
              </Grid>
            </React.Fragment>
          ))}

          <Grid item xs={0.83} sx={{ p: 0.5, py: 2 }}>
            Document #
          </Grid>
          {projectDocs[0] && (
            <Grid item xs={2} sx={{ p: 1, px: 0 }}>
              <SelectDoc
                onSelectExistedDoc={onSelectExistedDoc}
                selectedMrqDoc={selectedMrqDoc}
                projectDocs={projectDocs}
                setProjectDocs={setProjectDocs}
              />
            </Grid>
          )}

          <Grid item xs={2} sx={{ p: 1, px: 0 }}>
            <TextField
              fullWidth
              label="Or Create New Document #"
              variant="outlined"
              size="small"
              sx={{ ml: 1 }}
              value={newDoc}
              onChange={onChangeNewDoc}
            />{" "}
          </Grid>
          {newDoc && (
            <Grid item xs={1} sx={{ ml: 2, p: 1, px: 0 }}>
              <Button variant="contained" onClick={onSaveNewDoc}>
                Add Document
              </Button>
            </Grid>
          )}
        </Grid>
      </LabelledBox>
    </Box>
  );
}
