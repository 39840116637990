import { secondAxios } from "../../../../../../api/axios";

export const getItps = async () => {
  try {
    const response = await secondAxios.get("/itps");
    return response.data;
  } catch (error) {
    console.error("Error fetching ITPs:", error);
    throw new Error("An error occurred while fetching ITPs.");
  }
};

export const getItpsByProjectAndType = async (projectId, type) => {
  try {
    const response = await secondAxios.get(
      `/itps/project/${projectId}/type/${type}`
    );
    console.log("project itp", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching ITPs by project:", error);
    throw new Error(
      `An error occurred while fetching ITPs for project ${projectId}.`
    );
  }
};

export const createItp = async (itpData) => {
  try {
    // console.log("creating", itpData);

    const response = await secondAxios.post("/itps", itpData);
    return response.data;
  } catch (error) {
    console.error("Error creating ITP:", error);
    throw new Error("An error occurred while creating an ITP.");
  }
};

export const updateItp = async (id, itpData) => {
  console.log("updating", itpData);
  try {
    const response = await secondAxios.put(`/itps/${id}`, itpData);
    return response.data;
  } catch (error) {
    console.error("Error updating ITP:", error);
    throw new Error(`An error occurred while updating ITP with id ${id}.`);
  }
};

export const deleteItp = async (id) => {
  try {
    await secondAxios.delete(`/itps/${id}`);
    return { message: `ITP with id ${id} deleted successfully.` };
  } catch (error) {
    console.error("Error deleting ITP:", error);
    throw new Error(`An error occurred while deleting ITP with id ${id}.`);
  }
};
