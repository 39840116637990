// import project from './interfaces/projectInterface';
// import axois from '../../common/api/axois';
// import axios from 'axios';

import axios, { autodesk_axios } from '../../common/api/axios';
import Project from './interfaces/projectInterface';

export const getProjectsList = async () => {
  // const projectData = await axios.get('/projects')

  return [
    {
      id: '1',
      name: 'test1',
      people: ['ahmed', 'ali'],
      assymblies: ['as1', 'as2'],
      progress: 75,
    },
    {
      id: '2',
      name: 'test2',
      people: ['ahmed', 'ali'],
      assymblies: ['as1', 'as2'],
      progress: 75,
    },
    {
      id: '3',
      name: 'test3',
      people: ['ahmed', 'ali'],
      assymblies: ['as1', 'as2'],
      progress: 75,
    },
  ];
};

export const getProject = async (
  projectId: string | undefined,
): Promise<Project> => {
  const response = await axios.get(`/project/${projectId}`);
  return response.data;
};
export const getProjects = async (accessToken: string): Promise<Project[]> => {
  // const response = await axios.get('/project');
  console.log('client nameee', process.env.REACT_APP_CLIENT_NAME, accessToken);
  const response = await autodesk_axios.get(
    `/project/all/${process.env.REACT_APP_CLIENT_NAME}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const modifiedRes = response.data.map((res: any) => ({
    ...res,
    members: res['project_members'],
  }));
  console.log('projjj', modifiedRes);

  return modifiedRes;
};

export const createProject = async (
  parentId: string,
  project: any,
): Promise<void> => {
  console.log('creating', { project }, process.env.REACT_APP_CLIENT_NAME);
  const { progress, id, ...reducedProject } = project;
  const resp1 = await autodesk_axios.post('/project/create', {
    projectName: project.projectName,
    clientName: process.env.REACT_APP_CLIENT_NAME,
  });
  console.log('created', resp1);
  const body = {
    projectName: resp1.data.project.projectName,
    id: resp1.data.project.id,
  };
  console.log('body', body);
  await axios.post('/project/create', body);
  if (project.members && project.members.length > 0) {
    project.members.forEach(async (member: any) => {
      await autodesk_axios.post('/project/add-member', {
        projectId: body.id,
        userId: member.id,
        role: member.role,
      });
    });
  }
};

export const updateProject = async (project: Project): Promise<void> => {
  const { id, progress, ...reducedProject } = project;

  await axios.patch(`/project/${project.id}`, reducedProject);

  const reshapeProjectUsers = (p: Project) => {
    const users = p.users.map((u) => {
      return {
        id: u?.id,
        userId: u.userId,
        role: u.role,
        projectId: project.id,
      };
    });
    return users;
  };
  await axios.patch(
    `/project/update-user-role/${project.id}`,
    reshapeProjectUsers(project),
  );
};

export const deleteProject = async (projectId: string): Promise<void> => {
  await axios.delete(`/project/${projectId}`);
};
