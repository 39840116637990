import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button, Grid } from "@mui/material";
import DrawingsMaterialsTable from "../Tables/DrawingsMaterialsTable";
import MrqMaterialsTable from "../Tables/MrqMaterialsTable";
import AllocateToDrawingMaterialsComponent from "../Tables/AllocateToDrawingMaterialsComponent";
import { modalStyle } from "../../../../../../../components/modalStyle";

const drawingMaterialsTableType = ["srv"];
const mrqMaterialsTableType = ["mrq", "po"];
const mrvMaterialsTableType = ["mrv"];

export default function ChipModal({ type, entity, open, handleClose }) {
  const mainDetails = ["Number", "Date"];
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="h6" component="span" fontWeight="bold">
                {type.toUpperCase() + " Details : "}
              </Typography>
              {mainDetails.map((d) => {
                return (
                  <Grid
                    container
                    sx={{ mt: d === mainDetails[0] && 2 }}
                    key={d}
                  >
                    <Grid item xs={4}>
                      <Typography
                        variant="h7"
                        component="span"
                        fontWeight="bold"
                      >
                        {type.toUpperCase() + " " + d + " : "}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {entity[type + d]}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>

            <Grid item xs={8}>
              <Box sx={{ pb: 1 }}>
                <Typography variant="h6" component="span" fontWeight="bold">
                  {type.toUpperCase() + " Materials : "}(
                  {entity[type + "Materials"]?.length})
                </Typography>
              </Box>

              {drawingMaterialsTableType.includes(type) && (
                <DrawingsMaterialsTable
                  materials={entity?.[type + "Materials"]?.map((m) => {
                    return { ...m.material, quantity: m.quantity };
                  })}
                />
              )}

              {mrvMaterialsTableType.includes(type) && (
                <AllocateToDrawingMaterialsComponent
                  mrqMaterials={entity?.mrvMrqMaterials?.map((m) => {
                    return { ...m.mrqMaterial, quantity: m.quantity };
                  })}
                  selectedDrawingMaterials={entity?.mrvMaterials?.map(
                    (m) => m.material
                  )}
                  disabled
                />
              )}

              {mrqMaterialsTableType.includes(type) && (
                <MrqMaterialsTable
                  mrqMaterials={
                    type === "mrq"
                      ? entity?.[type + "Materials"]
                      : entity?.[type + "Materials"]?.map((m) => m.mrqMaterial)
                  }
                  entity={entity}
                />
              )}
            </Grid>
          </Grid>
          <Box sx={{ textAlign: "right", pt: 2 }}>
            <Button variant="contained" onClick={handleClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
