import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React from "react";
import { DrawingBomContext } from "../../../DrawingBomContext";
import { GenerateTableCols } from "../cols";
import { lightTable } from "../../../../../../../../../styles/muiProps";

export default function GenerateTable({ generatingData, setGeneratingData }) {
  const { initialGeneratingData } = React.useContext(DrawingBomContext);

  const handleSaveCell = (cell, value) => {
    generatingData[cell.row.index][cell.column.id] = value;

    setGeneratingData([...generatingData]);
  };

  React.useEffect(() => {
    setGeneratingData(initialGeneratingData);
  }, []);

  const table = useMaterialReactTable({
    columns: GenerateTableCols,
    data: generatingData,
    enableEditing: true,
    editDisplayMode: "table",
    muiEditTextFieldProps: ({ cell }) => ({
      onBlur: (event) => {
        handleSaveCell(cell, event.target.value);
      },
    }),
    ...lightTable,

    muiTableContainerProps: { sx: { maxHeight: "250px" } },
  });

  return <MaterialReactTable table={table} />;
}
