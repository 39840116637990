import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import SelectFromExistedEntities from './SelectFromCurrentEntities';
import AddNewEntity from './AddNewEntity';
import { addMaterialsTo } from './api';
import { useMcsContext } from '../../../MCS.context';

// entityType = po || mrv || srv
const initialEntityData = { number: '', date: '' };

export default function AddEntityForm({
  entityType,
  table,
  drawingMaterials,
  mrqMaterials,
  onCloseModal,
  onAllocateAll,
  mrqId,
}) {
  const materials = ['srv'].includes(entityType)
    ? drawingMaterials || []
    : mrqMaterials || [];
  const { project, setProject, selectedBidVendors } = useMcsContext();
  const [entityTextData, setEntityTextData] = React.useState(initialEntityData);

  const [actionRadioBtnsData, setActionRadioBtnsData] =
    React.useState(initialEntityData);

  const onSelectEntity = (event) => {
    // setSelectedCurrentAction(event.target.value);
  };

  const onClickRadioBtn = (e) => {
    const selectedNumber = e.target.value;
    setEntityTextData(initialEntityData);
    setActionRadioBtnsData({
      number: selectedNumber,
      date: project?.[entityType + 's']?.filter(
        (a) => a.number === selectedNumber,
      )[0]?.date,
    });
  };

  const onTextChange = (e) => {
    e.preventDefault();
    setActionRadioBtnsData(initialEntityData);
    setEntityTextData({ ...entityTextData, [e.target.name]: e.target.value });
  };

  const onSubmit = async () => {
    const body = {
      number: actionRadioBtnsData.number || entityTextData.number,
      date: actionRadioBtnsData.date || entityTextData.date,
      mrqId: entityType === 'mrv' ? mrqId : project.selectedMrq?.id,
      mrqMaterialsIds: materials.map((m) => m.id),
      drawingMaterials,
      mrqMaterials,
      selectedDrawings: project.selectedDrawings,
      selectedBidVendors,
      projectId: project.id,
      po: entityType === 'mrv' && project.selectedPo,
    };

    console.log({ body });

    const { data, error } = await addMaterialsTo({
      to: entityType,
      body,
    });

    console.log({ data, error });
    if (data?.[0] || data?.id) {
      console.log({ data });
      switch (entityType) {
        case 'po':
          console.log('po', data);
          setProject({ ...project, selectedMrq: data, pos: [] });
          break;
        case 'mrv':
          console.log('mrv', data);
          setProject({ ...project, selectedPo: data, mrvs: [] });
          break;

        case 'srv':
          console.log('srv', data);
          setProject({ ...project, selectedDrawings: data, srvs: [] });
          break;

        default:
          break;
      }
      // TODO: manage State
      onCloseModal();
      setEntityTextData(initialEntityData);
      setActionRadioBtnsData(initialEntityData);
    } else {
      alert('failed');
    }
  };

  const allDrawingMaterials = [];

  mrqMaterials?.forEach((mm) => {
    allDrawingMaterials.push(...mm.materials);
  });

  const disableAddSrv = () => {
    let disable = false;

    materials?.forEach((m) => {
      if (!m.mrvMaterials?.[0]) disable = true;
    });
    return disable;
  };
  return (
    <>
      <Grid container>
        <Grid item xs={5} sx={{ pr: 2 }}>
          <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>
            Add {entityType.toUpperCase()} :
          </Typography>

          <Box sx={{ px: 1 }}></Box>
          <Typography variant="h6">
            Current {entityType.toUpperCase()}s in this Project:
          </Typography>

          <SelectFromExistedEntities
            onSelectEntity={onSelectEntity}
            actionRadioBtnsData={actionRadioBtnsData}
            onClickRadioBtn={onClickRadioBtn}
            currentEntity={entityType.toLowerCase()}
          />

          <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
            <span style={{ fontSize: '14px' }}>or</span> Create new{' '}
            {entityType.toUpperCase()} :
          </Typography>

          <Box sx={{ px: 3 }}>
            <AddNewEntity
              currentEntity={entityType.toLowerCase()}
              entityTextData={entityTextData}
              onTextChange={onTextChange}
            />
          </Box>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>
            Selected Materials: ({materials.length})
          </Typography>

          {table}
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      <Box sx={{ textAlign: 'right', pt: 2 }}>
        {/* number:{actionRadioBtnsData.number || entityTextData.number} date:
          {actionRadioBtnsData.date || entityTextData.date} */}

        {entityType === 'mrv' ? (
          <>
            <Typography
              style={{
                display: 'inline-block',
                color: 'primary.main',
                fontSize: '16px',
              }}
            >
              Allocated Drawing Materials:
              <span
                style={{
                  fontWeight: 700,
                }}
              >
                {` ( ${drawingMaterials.length}/${allDrawingMaterials.length} )`}
              </span>
            </Typography>
            <Button variant="outlined" onClick={onAllocateAll} sx={{ mx: 1 }}>
              {drawingMaterials.length === allDrawingMaterials.length
                ? 'Clear Allocation'
                : 'Allocate All'}
            </Button>
          </>
        ) : (
          ''
        )}

        {entityType === 'srv' && disableAddSrv() && (
          <Typography sx={{ width: '100%', textAlign: 'center', color: 'red' }}>
            * Please make sure that all the selected Materials are included in a
            MRV
          </Typography>
        )}
        <Button
          variant="contained"
          onClick={onSubmit}
          disabled={entityType === 'srv' && disableAddSrv()}
        >
          Submit
        </Button>
      </Box>
    </>
  );
}
