import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  MenuItem,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { getUsers } from "../api";
import { AuthContext } from "../../../../../context/authContext";

const roles = ["Project Manager", "Engineer", "Senior Engineer"];

const CreateProjectDialog = ({ open, onClose, onSave }) => {
  const [projectName, setProjectName] = useState("");
  const [members, setMembers] = useState([]);
  const [newMemberId, setNewMemberId] = useState("");
  const [newMemberRole, setNewMemberRole] = useState(roles[0]);
  const [users, setUsers] = useState([]);
  const { accessToken } = useContext(AuthContext);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getUsers(accessToken);
        setUsers(response); // Assuming the API returns an array of users
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    accessToken && fetchUsers();
  }, [accessToken]);

  const addMember = () => {
    const selectedUser = users.find((user) => user.id === newMemberId);
    if (selectedUser) {
      setMembers((prev) => [
        ...prev,
        { id: newMemberId, name: selectedUser.username, role: newMemberRole },
      ]);
      setNewMemberId("");
      setNewMemberRole(roles[0]);
    }
  };

  const removeMember = (index) => {
    setMembers((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSave = () => {
    if (projectName && members.length > 0) {
      //   console.log('ddd', onSave);

      onSave({ projectName, members });
      setProjectName("");
      setMembers([]);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Create New Project</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <TextField
          label="Project Name"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          fullWidth
        />
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <TextField
            label="Member Name"
            select
            value={newMemberId}
            onChange={(e) => setNewMemberId(e.target.value)}
            sx={{ flex: 1 }}
          >
            {users.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.username}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Role"
            select
            value={newMemberRole}
            onChange={(e) => setNewMemberRole(e.target.value)}
            sx={{ flex: 1 }}
          >
            {roles.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </TextField>
          <Button
            variant="contained"
            onClick={addMember}
            disabled={!newMemberId || !newMemberRole}
          >
            Add Member
          </Button>
        </Box>
        <Box>
          {members.map((member, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 1,
              }}
            >
              {`${member.name} (${member.role})`}
              <IconButton onClick={() => removeMember(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSave}
          variant="contained"
          disabled={!projectName || members.length === 0}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateProjectDialog;
