import { Box } from "@mui/material";

export const taggedMrqMaterialsCols = [
  {
    accessorKey: "tagNumbers",
    header: "Tag Number",
    enableEditing: false,
    size: 100,
    Cell: ({ row }) => {
      return (
        <Box>
          {row.original.materials?.map((m, i) => {
            return (
              <p key={i}>
                - {m.taggedMaterial?.tagNumber || m.bulkMaterial?.item}
              </p>
            );
          })}
        </Box>
      );
    },
  },
  {
    header: "Description",
    accessorKey: "description",
    enableEditing: false,
    size: 200,
  },
  {
    header: "Quantity",
    accessorKey: "quantity",
    enableEditing: false,
    size: 50,
  },
  {
    header: "Remarks",
    accessorKey: "remarks",
    enableEditing: false,
    size: 150,
  },
];

export const bulkMrqMaterialsCols = [
  {
    accessorKey: "bulkMaterial.item",
    header: "Items",
    enableEditing: false,
    grow: false,
    size: 50,
    Cell: ({ row }) => {
      console.log({ row: row.original });
      return (
        <Box>
          {row.original.materials
            ?.map((m) => m?.bulkMaterial?.item)
            .sort()
            .map((item, i) => {
              return <p key={i}>- {item}</p>;
            })}
        </Box>
      );
    },
  },
  {
    accessorKey: "quantity",
    header: "Quantity",
    enableEditing: false,
    grow: false,
    size: 50,
  },
  {
    accessorKey: "materialCode",
    header: "Material Code",
    enableEditing: false,

    size: 120,
  },
  {
    accessorKey: "description",
    header: "Description",
    enableEditing: false,

    size: 120,
  },
];

export const bulkDescriptionMrqMaterialsCols = [
  // {
  //   accessorKey: 'item',
  //   header: 'Mark #',
  //   enableEditing: false,
  //   grow: false,
  //   size: 50,
  //   Cell: ({ row }) => {
  //     console.log({ row: row.original });
  //     return (
  //       <Box>
  //         {row.original.materials
  //           ?.map((m) => m?.bulkMaterial?.item || m?.item)
  //           .sort()
  //           .map((item, i) => {
  //             return <p key={i}>- {item}</p>;
  //           })}
  //       </Box>
  //     );
  //   },
  // },
  {
    accessorKey: "description",
    header: "Description",
    enableEditing: false,

    size: 230,
  },
  {
    accessorKey: "quantity",
    header: "Quantity",
    enableEditing: false,
    grow: false,
    size: 10,
  },
  {
    accessorKey: "unit",
    header: "Unit",
    enableEditing: false,

    size: 60,

    Cell: ({ row }) => {
      const m = row.original.materials?.[0];
      let unit;
      if (m.length) {
        // console.log({ len: m.length });
        unit = m.length.split(" ").pop();
      } else {
        unit = "No.";
      }
      return <Box>{unit}</Box>;
    },
  },
  {
    accessorKey: "wt",
    header: "wt. (kg)",
    enableEditing: false,

    size: 10,
  },
];

// export const mrqMaterialsCols = (materials) => {
//   const rows = materials || [];
//   const isTaggedMaterials = rows?.[0]?.materials[0]?.taggedMaterial?.id;
//   const isBulkMaterials = rows?.[0]?.materialCode;

//   const cols = isTaggedMaterials
//     ? taggedMrqMaterialsCols
//     : isBulkMaterials
//       ? bulkMrqMaterialsCols
//       : bulkDescriptionMrqMaterialsCols;

//   return cols;
// };
