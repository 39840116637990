import { useContext, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Box, IconButton, Collapse, Typography, MenuItem } from "@mui/material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ChatIcon from "@mui/icons-material/Chat";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { useDCS } from "./DCSContext";

// Mock data for documents with revisions
// const documentData = [
//   {
//     document: "Procurement of Tank Plates",
//     docNum: "001",
//     revNum: "3",
//     docLink: "https://example.com/project-plan",
//     progress: 85,
//     responsible: "Omar Mokhtar",
//     revisions: [
//       { version: "Rev 1", date: "2023-01-01", changes: "Initial draft" },
//       { version: "Rev 2", date: "2023-02-15", changes: "Added milestones" },
//       { version: "Rev 3", date: "2023-03-10", changes: "Finalized content" },
//     ],
//   },
//   {
//     document: "Procurement of Nozzles",
//     docNum: "002",
//     revNum: "2",
//     docLink: "https://example.com/budget-overview",
//     progress: 90,
//     responsible: "Omar Mokhtar",
//     revisions: [
//       { version: "Rev 1", date: "2023-01-20", changes: "Initial draft" },
//       {
//         version: "Rev 2",
//         date: "2023-02-10",
//         changes: "Updated budget figures",
//       },
//     ],
//   },
// ];

const RACITable = ({ handleButtonClick, allUsers, currentUser, urnLoaded }) => {
  const { racis } = useDCS();
  const [expandedRows, setExpandedRows] = useState({});
  console.log("rr", racis);

  const toggleRow = (rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const columns = useMemo(
    () => [
      {
        id: "document",
        header: "Document Info",
        columns: [
          {
            accessorKey: "document",
            header: "Document",
            size: 120, // Reduced width
            Cell: ({ cell, row }) => (
              <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <IconButton onClick={() => toggleRow(row.index)}>
                  {expandedRows[row.index] ? (
                    <KeyboardArrowUp />
                  ) : (
                    <KeyboardArrowDown />
                  )}
                </IconButton>
                {cell.getValue()}
              </Box>
            ),
          },
          {
            accessorKey: "docNum",
            header: "Doc #",
            size: 10, // Reduced width
          },
          {
            accessorKey: "revNum",
            header: "Rev #",
            size: 10, // Reduced width
            enableEditing: (row) =>
              currentUser
                ? row.original?.task_members?.includes(currentUser)
                : false,
          },
          {
            accessorKey: "docLink",
            header: "Link",
            size: 60, // Reduced width
            Cell: ({ cell }) => (
              <a
                href={cell.getValue()}
                target="_blank"
                rel="noopener noreferrer"
              >
                Link
              </a>
            ),
          },
          {
            accessorKey: "progress",
            header: "P",
            size: 10, // Reduced width
            Cell: ({ cell, row }) => {
              const timesheets = row.original?.timesheets || [];
              let progress = timesheets.reduce(
                (acc, t) => acc + t?.progress,
                0
              );
              return `${progress}%`;
            },
          },
        ],
      },
      {
        accessorKey: "responsible",
        header: "R",
        enableColumnActions: false,
        enableSorting: false,
        size: 70, // Reduced width
        muiEditTextFieldProps: () => ({
          children: allUsers?.map((user) => (
            <MenuItem key={user.email} value={user.email}>
              {user.email}
            </MenuItem>
          )),
          select: true,
        }),
      },
      {
        accessorKey: "accountables",
        header: "A",
        enableEditing: false,
        size: 60, // Reduced width
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ row }) => (
          <IconButton onClick={() => handleButtonClick("acc", row)}>
            <FormatListBulletedIcon />
          </IconButton>
        ),
      },
      {
        accessorKey: "consulted",
        header: "C",
        size: 60, // Reduced width
        enableEditing: false,
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ row }) => (
          <IconButton onClick={() => handleButtonClick("cons", row)}>
            <FormatListBulletedIcon />
          </IconButton>
        ),
      },
      {
        accessorKey: "informed",
        header: "I",
        enableEditing: false,
        enableColumnActions: false,
        enableSorting: false,
        size: 60, // Reduced width
        Cell: ({ row }) => (
          <IconButton onClick={() => handleButtonClick("inf", row)}>
            <FormatListBulletedIcon />
          </IconButton>
        ),
      },
      {
        accessorKey: "mail",
        header: "Mail",
        enableColumnActions: false,
        enableSorting: false,
        enableEditing: false,
        size: 60, // Reduced width
        Cell: ({ row }) => (
          <IconButton onClick={() => handleButtonClick("mail", row)}>
            <AttachEmailIcon />
          </IconButton>
        ),
      },
      {
        accessorKey: "chat",
        header: "Chat",
        enableColumnActions: false,
        enableSorting: false,
        enableEditing: false,
        size: 60, // Reduced width
        Cell: ({ row }) => (
          <IconButton onClick={() => handleButtonClick("comments", row)}>
            <ChatIcon />
          </IconButton>
        ),
      },
      // {
      //   accessorKey: "timesheet",
      //   header: "Timesheet",
      //   enableEditing: false,
      //   size: 70, // Reduced width
      //   Cell: ({ row }) => (
      //     <IconButton onClick={() => handleButtonClick("timesheet", row)}>
      //       <PostAddIcon />
      //     </IconButton>
      //   ),
      // },
      {
        id: "versions",
        header: "Revisions",
        size: 200, // Reduced width
        Cell: ({ row }) => (
          <Collapse in={expandedRows[row.index]} timeout="auto" unmountOnExit>
            <Box>
              {row.original.revisions?.map((rev, index) => (
                <Box key={index} sx={{ margin: "1rem 0" }}>
                  <Typography>
                    <strong>Version:</strong> {rev.version}
                  </Typography>
                  <Typography>
                    <strong>Date:</strong> {rev.date}
                  </Typography>
                  <Typography>
                    <strong>Changes:</strong> {rev.changes}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Collapse>
        ),
      },
    ],
    [expandedRows, currentUser, allUsers, handleButtonClick]
  );

  const table = useMaterialReactTable({
    columns,
    data: racis || [],
    // enableColumnOrdering: true,
    // enableRowSelection: true,
    paginationDisplayMode: "pages",
  });

  return <MaterialReactTable table={table} />;
};

export default RACITable;
