import DCSTable from "./Table/index";
import { DCSProvider } from "./Table/DCSContext";
import { Stack, Typography, Select, MenuItem } from "@mui/material";
import StyleIcon from "@mui/icons-material/Style";
import { useState } from "react";

export const Types = [
  "MRQ",
  "RFI",
  "Client",
  "Engineering",
  "Project Management",
  "Quality Control",
  "Fabrication",
  "Vendor",
];

export default function DocReviewer({ projectId }) {
  const [selectedOption, setSelectedOption] = useState("Engineering");
  return (
    <DCSProvider type={selectedOption}>
      <>
        <Stack
          direction={"row"}
          paddingBottom={2}
          spacing={2}
          sx={{ alignItems: "center" }}
        >
          <StyleIcon color="secondary" />
          <Typography variant="h5" color="darkBlue">
            Document Type:
          </Typography>
          <Select
            value={selectedOption}
            onChange={(event) => setSelectedOption(event.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Select View" }}
            sx={{ width: 100, backgroundColor: "white", height: 30 }}
          >
            {Types.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <DCSTable projectId={projectId || ""} type={selectedOption} />
      </>
    </DCSProvider>
  );
}
