import { getDrawingData } from '../../DrawingRegisterTab/api';

export const onAddingNewDrawing = async ({
  drawing,
  selectedDrawings,
  setSelectedDrawings,
}) => {
  const materials = await getDrawingData(drawing.id);

  if (materials?.[0]?.tagNumber) {
    const taggedMaterials = materials.map((m) => {
      const { component, size } = m.taggedMaterial;

      const description = !m.description
        ? component + ' ' + size
        : m.description;

      return { ...m, description };
    });

    setSelectedDrawings([
      ...selectedDrawings,
      { ...drawing, materials: taggedMaterials },
    ]);
  } else {
    setSelectedDrawings([...selectedDrawings, { ...drawing, materials }]);
  }
};
