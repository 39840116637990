import { Badge, Box, Button, Chip, IconButton, MenuItem } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { CircularProgressWithLabel } from "../../../../../../../components/widgets/CircularProgressWithLabel";

const getOrdinalSuffix = (number) => {
  const suffixes = ["th", "st", "nd", "rd"];
  const v = number % 100;
  return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
};

const convertDateString = (dateString) => {
  const dateObject = new Date(dateString);
  // Format the date
  // const formattedDate = `${getOrdinalSuffix(dateObject.getDate())} ${dateObject.toLocaleString('en-US', { month: 'short' })} ${dateObject.getFullYear()}`;
  const formattedDate = `${dateObject.getDate()}-${dateObject.toLocaleString(
    "en-US",
    { month: "short" }
  )}-${dateObject.getFullYear()}`;
  return <p>{formattedDate}</p>;
};

const statusColorMap = {
  "Not Started": "default",
  Progress: "primary",
  Hold: "warning",
  Completed: "success",
  Delayed: "error",
};

export const taskListColumns = (
  project_members,
  handleButtonClick,
  currentUser,
  projectLeads,
  handleCommentDialogOpen,
  documentList
) => [
  {
    accessorKey: "id",
    header: `ID`,
    enableEditing: false,
  },
  {
    accessorKey: "startDate",
    header: `Start`,
    size: 50,
    enableColumnFilter: false,
    Cell: ({ cell }) => (
      <>
        {cell.getValue() ? (
          <div style={{ whiteSpace: "wrap" }}>
            {convertDateString(cell.getValue())}
          </div>
        ) : (
          ""
        )}
      </>
    ),
    muiEditTextFieldProps: ({ cell }) => ({
      type: "date",
    }),
  },
  {
    accessorKey: "taskCode",
    header: `Task Code`,
    enableEditing: (row) => {
      if (row.original.task === "") return true;
      else if (currentUser)
        return row?.original?.task_members?.includes(currentUser);
      else return false;
    },
    muiEditTextFieldProps: ({ value, onChange }) => ({
      children: (
        [...documentList, { title: "none", docNumber: "0" }] || []
      )?.map((d, index) => (
        <MenuItem key={index} value={`Pi-${d.title}`}>
          {`Pi-${d.title}`}
        </MenuItem>
      )),
      select: true,
      SelectProps: {
        // multiple: true,
        // renderValue: (selected) => (
        //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        //     {selected.map((value, index) => (
        //       <Chip key={index} label={value} />
        //     ))}
        //   </Box>
        // ),
      },
    }),
    size: 100,
  },
  {
    accessorKey: "task",
    header: `Task`,
    enableEditing: (row) => {
      if (row.original.task === "") return true;
      else if (currentUser)
        return row?.original?.task_members?.includes(currentUser);
      else return false;
    },
    size: 280,
  },
  {
    accessorKey: "task_members",
    header: `Responsible`,
    size: 100,
    enableSorting: false,
    Cell: ({ cell, row }) => {
      return (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          {cell.getValue()
            ? cell
                .getValue()
                .map((user, index) => (
                  <Chip key={index} label={user} size="small" />
                ))
            : ""}
        </Box>
      );
    },
    muiEditTextFieldProps: ({ value, onChange }) => ({
      children: (project_members || [])
        .concat(projectLeads || [])
        ?.map((user, index) => (
          <MenuItem key={index} value={user.user.username}>
            {user.user.username}
          </MenuItem>
        )),
      select: true,
      SelectProps: {
        multiple: true,
        renderValue: (selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value, index) => (
              <Chip key={index} label={value} />
            ))}
          </Box>
        ),
      },
    }),
  },
  {
    accessorKey: "priority",
    header: "Priority",
    enableColumnFilter: false,
    size: 90,
    muiEditTextFieldProps: () => ({
      children: ["High", "Medium", "Low"].map((func) => (
        <MenuItem key={func} value={func}>
          {func}
        </MenuItem>
      )),
      select: true,
    }),
  },

  {
    accessorKey: "targetDate",
    header: `Due`,
    size: 50,
    enableColumnFilter: false,
    Cell: ({ cell }) => (
      <>
        {cell.getValue() ? (
          <div style={{ whiteSpace: "wrap" }}>
            {convertDateString(cell.getValue())}
          </div>
        ) : (
          ""
        )}
      </>
    ),
    muiEditTextFieldProps: ({ cell }) => ({
      type: "date",
    }),
  },
  {
    accessorKey: "estimatedTime",
    header: "Est. Time",
    enableEditing: true,
    enableColumnFilter: false,
    enableSorting: false,
    size: 30,
  },
  {
    accessorKey: "hours",
    header: "Hours",
    enableEditing: false,
    enableColumnFilter: false,
    enableSorting: false,
    size: 30,
  },
  {
    accessorKey: "progress",
    header: "Progress",
    enableEditing: false,
    enableColumnFilter: false,
    size: 60,
    enableSorting: false,
    Cell: ({ cell, row }) => (
      <CircularProgressWithLabel value={cell.getValue()} />
    ),
  },
  {
    accessorKey: "status",
    header: `Status`,
    enableColumnFilter: false,
    size: 80,
    muiEditTextFieldProps: () => ({
      children: ["Not Started", "Progress", "Hold", "Completed", "Delayed"].map(
        (func) => (
          <MenuItem key={func} value={func}>
            {func}
          </MenuItem>
        )
      ),
      select: true,
    }),
    Cell: ({ cell }) => (
      <>
        {cell.getValue() ? (
          <Chip
            label={cell.getValue()}
            size="small"
            color={statusColorMap[cell.getValue()] || "default"}
          />
        ) : (
          <></>
        )}
      </>
    ),
  },
  {
    accessorKey: "chat",
    header: `Chat`,
    enableEditing: false,
    enableSorting: false,
    enableColumnFilter: false,
    size: 50,
    Header: () => <ChatIcon />,
    Cell: ({ row }) => (
      <IconButton
        onClick={() => handleCommentDialogOpen(row)}
        // disabled={project_members?.includes(currentUser) || projectLeads.includes(currentUser) ? false : true}
      >
        {/* <Badge badgeContent={2} color="primary" > */}
        <ChatIcon />
        {/* </Badge> */}
      </IconButton>
    ),
  },
  {
    accessorKey: "attachment",
    header: `Attachment`,
    enableEditing: false,
    enableSorting: false,
    enableColumnFilter: false,
    size: 20,
    Header: () => <AttachFileIcon />,
    Cell: ({ row }) => (
      <IconButton
        onClick={() => handleButtonClick("attachment", row)}
        disabled={
          project_members?.includes(currentUser) ||
          projectLeads.includes(currentUser)
            ? false
            : true
        }
      >
        <AttachFileIcon />
      </IconButton>
    ),
  },
  {
    accessorKey: "timesheet",
    header: `Timesheet`,
    enableEditing: false,
    enableSorting: false,
    enableColumnFilter: false,
    size: 20,
    Header: () => <PostAddIcon />,
    Cell: ({ row }) => (
      <IconButton
        onClick={() => handleButtonClick("timesheet", row)}
        // disabled={project_members?.includes(currentUser) || projectLeads.includes(currentUser) ? false : true}
      >
        <PostAddIcon />
      </IconButton>
    ),
  },
];
