import { secondAxios } from "../../../../../../../../api/axios";

export const addMaterialsTo = async ({ to, body }) => {
  const reqBody = {
    ...body,
    [`${to.toLowerCase()}Number`]: body.number,
    [`${to.toLowerCase()}Date`]: body.date,
  };

  console.log({ reqBody });
  const { data, error } = await secondAxios.post(to, reqBody);

  return { data, error };
};
