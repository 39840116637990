import React, { createContext, useContext } from "react";
import { getDrawingData } from "../../DrawingRegisterTab/api";
import { createMrq } from "../api";
import { MCSContext } from "../../MCS.context";
import { onAddingNewDrawing } from "./handleAddingNewDrawing";
import { getObject } from "./ConsolidateMaterials";

const DrawingBomContext = createContext();

export const useDrawingBomContext = () => useContext(DrawingBomContext);

let currentDate = new Date();
const offset = currentDate.getTimezoneOffset();
currentDate = new Date(currentDate.getTime() - offset * 60 * 1000);

function DrawingBomProvider({ children }) {
  const {
    project,
    // setMrqSelection,
    setCurrentTab,
    setSelectedDrawings,
    updateProjectAfterCreateMrq,
  } = React.useContext(MCSContext);

  const { selectedDrawings } = project;

  const [selectionMaterials, setSelectionMaterials] = React.useState({});
  const [consolidatedMaterials, setConsolidatedMaterials] = React.useState([]);
  // selectionMaterials: {drawingId: [materials], }
  const [openConsolidateModal, setOpenConsolidateModal] = React.useState(false);
  const [openAddSrvModal, setOpenAddSrvModal] = React.useState(false);

  const [generatingData, setGeneratingData] = React.useState([]);

  const [isMrqFormVisible, setIsMrqFormVisible] = React.useState(false);

  const initialMrqDetails = {
    projectId: project.id,
    projectName: project.name || "",
    mrqDate: currentDate.toISOString().split("T")[0],
  };
  const [mrqDetails, setMrqDetails] = React.useState(initialMrqDetails);
  const [selectedMrqDoc, setSelectedMrqDoc] = React.useState("");

  // Deal With Drawings
  const handleAddingNewDrawing = async ({ drawing }) => {
    if (selectedDrawings.find((d) => d.id === drawing.id)) {
      alert("Drawing is Already selected !");
    } else {
      await onAddingNewDrawing({
        drawing,
        selectedDrawings,
        setSelectedDrawings,
      });
    }
  };

  const removeSelectedDrawing = (id) => {
    setSelectedDrawings(
      selectedDrawings.filter((drawing) => drawing.id !== id)
    );
    setSelectionMaterials[id] = [];
    setSelectionMaterials({ ...setSelectionMaterials });
  };

  const onRemoveAll = () => {
    setSelectedDrawings([]);
    setSelectionMaterials({});
  };

  // Deal With Consolidate Materials
  const handleOpenConsolidateModal = () => setOpenConsolidateModal(true);
  const handleCloseConsolidateModal = () => setOpenConsolidateModal(false);

  const obj = getObject({ consolidatedMaterials });

  const consolidatedMaterialsData = Object.values(obj).map((m) => {
    const materials = consolidatedMaterials.filter(
      (material) => material.materialCode === m.materialCode
    );
    return { ...m, materials };
  });

  const consolidatedDescriptionMaterialsData = Object.values(obj).map((m) => {
    const materials = consolidatedMaterials.filter(
      (material) => material.description === m.description
    );
    return { ...m, materials };
  });

  const consolidatedTaggedMaterialsData = Object.values(obj).map((m) => {
    const materials = consolidatedMaterials.filter(
      (material) => material.description === m.description
    );
    return {
      ...m,
      materials,
      description: m.description
        ? m.description
        : materials[0].taggedMaterial.component +
          " " +
          materials[0].taggedMaterial.size,
    };
  });

  const initialGeneratingData = consolidatedMaterialsData?.map((m) => ({
    softwareId: m.softwareId,
    materialNo: m.materialNo,
    actualTotalWeight: m.totalWtInKgs,
    materials: m.materials,
  }));

  function changeSelectionMaterials(drawingSelectedMaterials) {
    // console.log('from context', drawingSelectedMaterials);
    setSelectionMaterials({
      ...selectionMaterials,
      ...drawingSelectedMaterials,
    });
  }

  function consolidateMaterials() {
    let materials = [];
    // console.log({ selectionMaterials });
    Object.values(selectionMaterials).forEach((val) => {
      // console.log({ val });
      val.forEach((material) => materials.push(material));
    });

    // console.log({ materials });
    if (materials[0]) {
      setConsolidatedMaterials(materials);
      return true;
    } else {
      alert("No materials selected");
      return false;
    }
  }

  function onConsolidate() {
    setIsMrqFormVisible(false);
    setMrqDetails(initialMrqDetails);
    setGeneratingData(initialGeneratingData);

    const isConsolidated = consolidateMaterials();

    if (isConsolidated) {
      handleOpenConsolidateModal(true);
    }
  }

  // Deal With Add Srvv Materials
  const handleOpenAddSrvModal = () => setOpenAddSrvModal(true);
  const handleCloseAddSrvModal = () => setOpenAddSrvModal(false);

  function onAddSrv() {
    const isConsolidated = consolidateMaterials();

    if (isConsolidated) {
      console.log({ consolidatedMaterials });
      handleOpenAddSrvModal(true);
    }
  }

  // Deal With Create MRQ
  const onClickAddMrq = () => {
    setIsMrqFormVisible(true);
  };

  const onMrqDetailsChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    // console.log(name, value);
    setMrqDetails({ ...mrqDetails, [name]: value });
  };

  const onCreateMrq = async () => {
    const createdMrq = {
      ...mrqDetails,
      document: selectedMrqDoc,
      selectedDrawings,
      mrqMaterials: consolidatedMaterials?.[0].tagNumber
        ? consolidatedTaggedMaterialsData
        : consolidatedMaterials?.[0].materialCode
        ? generatingData
        : consolidatedDescriptionMaterialsData,
    };
    console.log({ createdMrq });
    if (!createdMrq.mrqNumber) {
      alert("Please Insert MRQ Number.");
    } else {
      const res = await createMrq({ createdMrq });

      if (res?.mrq?.id) {
        setOpenConsolidateModal(false);
        // TODO:
        // setMrqSelection({
        //   selectedMrq: '',
        //   revisions: [],
        //   selectedRevision: '',
        // });

        updateProjectAfterCreateMrq(res);
        setCurrentTab(1);
        // console.log('fromDrawingBomContext :', res);
      }
    }
  };

  return (
    <DrawingBomContext.Provider
      value={{
        handleAddingNewDrawing,
        selectedDrawings,
        removeSelectedDrawing,
        onRemoveAll,

        selectionMaterials,
        setSelectionMaterials,

        onConsolidate,
        openConsolidateModal,
        handleCloseConsolidateModal,
        consolidatedMaterials,
        consolidatedTaggedMaterialsData,
        consolidatedMaterialsData,
        consolidatedDescriptionMaterialsData,
        generatingData,
        setGeneratingData,
        initialGeneratingData,

        onAddSrv,
        openAddSrvModal,
        setOpenAddSrvModal,
        handleCloseAddSrvModal,
        changeSelectionMaterials,

        isMrqFormVisible,
        onClickAddMrq,
        mrqDetails,
        onMrqDetailsChange,
        selectedMrqDoc,
        setSelectedMrqDoc,
        onCreateMrq,
      }}
    >
      {children}
    </DrawingBomContext.Provider>
  );
}

export { DrawingBomProvider, DrawingBomContext };
