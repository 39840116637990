import React from "react";
// import { useParams } from 'react-router-dom';
import { Box } from "@mui/material";
import MrqSelector from "./components/MrqSelector";
import MrqDetails from "./components/MrqDetails";
import MrqMaterialsTable from "./components/MrqMaterialsTable";
import LabelledBox from "../../../../../../components/widgets/LabelledBox";

export default function Main() {
  return (
    <Box>
      <LabelledBox label="MRQ Manager">
        <MrqSelector />
      </LabelledBox>
      <MrqDetails />
      <MrqMaterialsTable />{" "}
      {/* <AddDataDialog open={dialog.open} onClose={onClose} /> */}
    </Box>
  );
}
