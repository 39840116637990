import * as React from "react";
import { MCSContextProvider } from "./MCS.context";

import Main from "./Main";

import LoadingComponent from "./LoadingComponent";
import { LoadingProvider } from "../../../../../context/LoadingContext";

export default function MCSPage() {
  return (
    <LoadingProvider>
      <MCSContextProvider>
        <LoadingComponent />
        <Main />
      </MCSContextProvider>
    </LoadingProvider>
  );
}
