import React from 'react'
import Button from '@mui/material/Button';

export const CustomButton = (props) => {
    return (
        <Button
            variant="contained"
            sx={{
                color: "white",
                backgroundColor: "#7a8bd0",
                '&:hover': {
                    backgroundColor: '#4e5fa2',
                    color: 'white',
                    transition: "all 0.5s"
                },
                mt: 1.2, ml: 1.5, mb: 1.5,

            }}
            onClick= {() => props.onClick()}
        >{props.value}</Button>
    )
}
