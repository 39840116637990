import { MenuItem, IconButton } from "@mui/material";
import MultiSelectCell from "./MultiSelectCell";
import { Notes, itpFuncs, itpProcedures } from "./utils";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ChatIcon from "@mui/icons-material/Chat";

export const itpColumns = [
  {
    accessorKey: "inspectionTestActivities",
    header: "Inspection / Test Activities",
    size: 300,
    // muiTableBodyCellProps: {
    //   align: "left",
    //   sx: {
    //     whiteSpace: "normal",
    //     wordWrap: "break-word",
    //   },
    // },
    // muiTableHeadCellProps: {
    //   sx: {
    //     whiteSpace: "normal",
    //     wordWrap: "break-word",
    //   },
    // },
  },
  {
    accessorKey: "rev",
    header: "rev",
    size: 10,
  },
  {
    accessorKey: "procedureNo",
    header: `Procedure No.`,
    size: 10,
    muiTableHeadCellProps: {
      align: "center",
    },
    // Cell: ({ cell }) => (
    //   <MultiSelectCell
    //     label="Procedures"
    //     cell={cell}
    //     choices={itpProcedures}
    //     initialState={[...cell.row._valuesCache.procedureNo]}
    //   />
    // ),
    muiEditTextFieldProps: () => ({
      children: itpProcedures.map((func) => (
        <MenuItem key={func} value={func}>
          {func}
        </MenuItem>
      )),
      select: true,
      SelectProps: {
        multiple: true,
        renderValue: (selected) => {
          console.log("selecteddd", { selected });
          return (
            // <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            //   {selected.map((value, index) => (
            //     <Chip
            //       key={index}
            //       label={
            //         allUsers?.find((member) => member.email === value)?.email
            //       }
            //     />
            //   ))}
            // </Box>
            selected.join(",")
          );
        },
      },
    }),
    enableEditing: true,
  },
  {
    accessorKey: "specificInstruction",
    header: "Specific Instruction",
    size: 10,
    muiTableHeadCellProps: {
      align: "center",
    },

    // Cell: ({ cell }) => (
    //   <MultiSelectCell
    //     label="Notes"
    //     cell={cell}
    //     choices={Notes}
    //     initialState={[...cell.row._valuesCache.specificInstruction]}
    //   />
    // ),
    muiEditTextFieldProps: () => ({
      children: Notes.map((n) => (
        <MenuItem key={n} value={n}>
          {n}
        </MenuItem>
      )),
      select: true,
    }),
    enableEditing: true,
  },
  {
    accessorKey: "authorizedInspectionDate",
    header: "Start Date",
    size: 50,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiEditTextFieldProps: {
      type: "date",
    },
  },
  {
    accessorKey: "representativeDate",
    header: "End Date",
    size: 50,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiEditTextFieldProps: {
      type: "date",
    },
  },
  {
    accessorKey: "progress",
    header: "Progress",
    size: 20,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiEditTextFieldProps: {
      type: "number",
    },
  },
  {
    id: "Authorized Inspector",
    header: "Authorized Inspector",
    size: 100,
    muiTableHeadCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "authorizedInspectionFunc",
        header: "*",
        size: 10,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiEditTextFieldProps: () => ({
          children: itpFuncs.map((func) => (
            <MenuItem key={func} value={func}>
              {func}
            </MenuItem>
          )),
          select: true,
        }),
      },
      {
        accessorKey: "authorizedInspectionInitials",
        header: "Initials",
        size: 40,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
    ],
  },
  {
    accessorKey: "Customer Representative",
    header: "Customer Representative",
    size: 100,
    muiTableHeadCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "representativeFunc",
        header: "*",
        size: 10,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiEditTextFieldProps: () => ({
          children: itpFuncs.map((func) => (
            <MenuItem key={func} value={func}>
              {func}
            </MenuItem>
          )),
          select: true,
        }),
      },
      {
        accessorKey: "representativeInitials",
        header: "Initials",
        size: 40,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
    ],
  },
  {
    accessorKey: "CDT Quality",
    header: "CDT Quality",
    size: 100,
    muiTableHeadCellProps: {
      align: "center",
    },
    columns: [
      {
        accessorKey: "cdtFunc",
        header: "*",
        size: 10,
        muiTableHeadCellProps: {
          align: "center",
        },
        muiEditTextFieldProps: () => ({
          children: itpFuncs.map((func) => (
            <MenuItem key={func} value={func}>
              {func}
            </MenuItem>
          )),
          select: true,
        }),
      },
      {
        accessorKey: "cdtInitials",
        header: "Initials",
        size: 40,
        muiTableHeadCellProps: {
          align: "center",
        },
      },
    ],
  },
  {
    accessorKey: "chat",
    header: "chat",
    Header: () => <ChatIcon />,
    size: 20,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiTableBodyCellProps: {
      align: "center",
    },
    enableEditing: false,
    enableColumnFilter: false,
    Cell: ({ row }) => (
      <IconButton>
        <ChatIcon />
      </IconButton>
    ),
  },
  {
    accessorKey: "upload",
    header: "doc upload",
    Header: () => <CloudUploadIcon />,
    size: 10,
    muiTableHeadCellProps: {
      align: "center",
      wordWrap: "break-word",
    },
    muiTableBodyCellProps: {
      align: "center",
      wordWrap: "break-word",
    },
    enableEditing: false,
    enableSorting: false,
    enableColumnFilter: false,
    Cell: ({ row }) => (
      <IconButton>
        <CloudUploadIcon />
      </IconButton>
    ),
  },
];
