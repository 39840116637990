import React from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { MCSContext } from "../MCS.context";
import LabelledBox from "../../../../../../components/widgets/LabelledBox";

const poDetailsGrid = [
  {
    label: "Project Name",
    field: "project",
    grid: 0.8,
  },
  {
    label: "MRQ #",
    field: "mrqNumber",
    grid: 0.6,
  },
  {
    label: "Made By",
    field: "drawnBy",
    grid: 0.8,
  },
  {
    label: "Date",
    field: "poDate",
    grid: 0.5,
  },
  {
    label: "PO Number",
    field: "poNumber",
    grid: 0.8,
  },
  {
    label: "PO Rev",
    field: "rev",
    grid: 0.6,
  },
  {
    label: "Title",
    field: "title",
    grid: 0.8,
  },
  {
    label: "Vendor Name",
    field: "vendorName",
    grid: 0.8,
  },

  {
    label: "Vendor Email",
    field: "vendorEmail",
    grid: 0.8,
  },
];

export default function PoDetails({ po }) {
  const { project } = React.useContext(MCSContext);
  // console.log({ po });

  const poDetails = {
    ...po,
    project: project.name,
    mrqNumber: po?.mrq?.mrqNumber,
    vendorName: po.poMaterials?.[0].vendor?.name,
    vendorEmail: po.poMaterials?.[0].vendor?.email,
  };
  return (
    <LabelledBox label="PO Details" sx={{ display: "inline-block" }}>
      <Grid container spacing={2} columns={{ xs: 6.8 }} sx={{ width: "900px" }}>
        {poDetailsGrid.map((item, index) => (
          <React.Fragment key={index}>
            <Grid item xs={item.grid}>
              <Typography>{item.label}</Typography>
              {/* <Box sx={{ backgroundColor: "red" }}>{item.label}</Box> */}
            </Grid>
            {/* <Grid item xs={item.label === "From" || item.label === "Title" ? 2.6 : 1} > */}
            <Grid
              item
              xs={
                ["Title", "Vendor Email", "Vendor Name"].includes(item.label)
                  ? 2.6
                  : 1
              }
            >
              {/* <Typography color={"GrayText"}>Values</Typography> */}
              <TextField
                id="outlined-basic"
                variant="outlined"
                hiddenLabel
                size="small"
                disabled
                fullWidth
                value={poDetails?.[item.field]}
                inputProps={{ sx: { padding: "2px", pl: "5px" } }}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </LabelledBox>
  );
}
