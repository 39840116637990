import React from 'react';

import { Button, Stack, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import { useDrawingBomContext } from '../DrawingBomContext';

const btnStyle = { mx: 0.4, my: 1 };
export default function TabControl() {
  const { onRemoveAll, onConsolidate, onAddSrv, consolidatedMaterials } =
    useDrawingBomContext();

  return (
    <Stack direction={'row'}>
      <Button
        variant="contained"
        color="error"
        startIcon={<DeleteIcon />}
        sx={btnStyle}
        onClick={onRemoveAll}
      >
        Remove All
      </Button>

      <Button
        variant="contained"
        startIcon={<ControlPointDuplicateIcon />}
        sx={btnStyle}
        onClick={onConsolidate}
      >
        Consolidate
      </Button>

      <Button
        variant="contained"
        startIcon={<LibraryAddOutlinedIcon />}
        sx={btnStyle}
        onClick={onAddSrv}
      >
        Add SRV
      </Button>

      <Button variant="contained" startIcon={<AccountTreeIcon />} sx={btnStyle}>
        Reconcile
      </Button>
      <Tooltip title="Material Traceability Record" placement="top">
        <Button
          variant="contained"
          startIcon={<ContentPasteSearchIcon />}
          sx={btnStyle}
        >
          MTR
        </Button>
      </Tooltip>
    </Stack>
  );
}
