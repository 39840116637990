import React from "react";
import { useLoading } from "../../../../../context/LoadingContext";
import { Backdrop, CircularProgress } from "@mui/material";

export default function LoadingComponent() {
  const { isLoading } = useLoading();

  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex:
          // (theme) => theme.zIndex.drawer + 1
          10000,
      }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
