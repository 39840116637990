import React, { useContext, useEffect } from "react";
// import ProjectListSideBar from "./ProjectListSideBar";
import TaskList from "./TaskList";
import { Box } from "@mui/material";
import { useState } from "react";
import { useGetProjects } from "./projectHooks";
// import { AuthContext } from "../../../../context/authContext";
import { AuthContext } from "../../../../../../context/authContext";
import { useParams } from "react-router-dom";
import HeadingComponent from "../../../../../../components/HeadingComponent";
import { DCSProvider } from "../../../../AllEquipments/CommonFeatures/DCSTab/Table/DCSContext";

const ProjectTasksPage = ({ catList, type }) => {
  const { id } = useParams("id");
  const { accessToken } = useContext(AuthContext);
  const {
    data,
    isError: isLoadingItemsError,
    isFetching,
    isLoading,
  } = useGetProjects({ accessToken });
  const [selectedProject, setSelectedProject] = useState({});

  useEffect(() => {
    console.log("dddddd", data, id);

    if (data) {
      let selectedProjectData = data.find(
        (project) => project.id === parseInt(id)
      );
      console.log("selected data ", selectedProjectData, id);

      setSelectedProject(selectedProjectData);
      console.log("selectedddd", selectedProjectData);
    }
  }, [id, data]);

  if (data)
    return (
      <Box padding={"2rem"}>
        <DCSProvider type={"Engineering"}>
          <HeadingComponent heading="Tasks" />
          <TaskList
            // key={projectId}
            selectedProject={selectedProject}
            catList={catList}
            type={type}
          />
        </DCSProvider>
      </Box>
    );
};

export default ProjectTasksPage;
