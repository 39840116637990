import {
  Autocomplete,
  Avatar,
  AvatarGroup,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
  menuClasses,
  selectClasses,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import sp_icon from "./assets/sp_icon.png";

import TaskOutlinedIcon from "@mui/icons-material/Task";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import DescriptionIcon from "@mui/icons-material/Description";
import ChatIcon from "@mui/icons-material/Chat";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

// export const columns = (onClick, urnLoaded) => [
//   {
//     accessorKey: "name",
//     header: "Project No.",
//     // size:true
//     grow: true,
//   },
//   {
//     accessorKey: "members",
//     header: "Members",
//     enableSorting: false,
//     enableColumnActions: false,
//   },
//   {
//     accessorKey: "project",
//     header: "Equipment",
//     size: 90,
//     grow: false,
//     Cell: ({ cell }) => {
//       return (
//         <Stack
//           direction="row"
//           justifyContent="center"
//           alignItems="center"
//           spacing={0}
//         >
//           {cell.getValue()?.map((equip) => {
//             return (
//               <Tooltip key={equip.id} title={equip.name} placement="top">
//                 <TypesIcon type={equip.type} />
//               </Tooltip>
//             );
//           })}
//         </Stack>
//       );
//     },
//   },
//   {
//     accessorKey: "dcs",
//     header: "Docs",
//     enableSorting: false,
//     enableColumnActions: false,
//     // muiTableHeadCellProps: { align: "center" },
//     Cell: ({ cell, row }) => {
//       console.log(row.original, row.original.projects?.[0]?.id);
//       return (
//         <IconButton
//           onClick={() =>
//             onClick(
//               row.original.id,
//               row.original.projects?.[0],
//               "#main-nav=DCS"
//             )
//           }
//         >
//           <FolderOpenIcon />
//         </IconButton>
//       );
//     },
//     grow: false,
//     enableEditing: false,
//     size: 60,
//   },
//   {
//     accessorKey: "task",
//     header: "Tasks",
//     enableSorting: false,
//     // muiTableHeadCellProps: { align: "center" },
//     enableColumnActions: false,
//     Cell: ({ cell, row }) => {
//       return (
//         <IconButton
//           onClick={() =>
//             onClick(
//               row.original.id,
//               row.original.projects?.[0],
//               "#main-nav=PCS&pcs-tab=Task%20List"
//             )
//           }
//         >
//           <TaskOutlinedIcon />
//         </IconButton>
//       );
//     },
//     grow: false,
//     enableEditing: false,
//     size: 60,
//   },
//   {
//     accessorKey: "raci",
//     header: "2D",
//     // muiTableHeadCellProps: { align: "center" },
//     enableSorting: false,
//     enableColumnActions: false,
//     Cell: ({ cell, row }) => {
//       return (
//         <IconButton
//           onClick={() =>
//             onClick(
//               row.original.id,
//               row.original.projects?.[0],
//               "#main-nav=DCS&dcs-tab=RACI"
//             )
//           }
//         >
//           <AssignmentIndIcon />
//         </IconButton>
//       );
//     },
//     grow: false,
//     enableEditing: false,
//     size: 60,
//   },
//   {
//     accessorKey: "drawing",
//     header: "BOM status",
//     enableSorting: false,
//     // muiTableHeadCellProps: { align: "center" },
//     enableColumnActions: false,
//     Header: (
//       <div>
//         <div>BOM</div>
//         <div>Status</div>
//       </div>
//     ),
//     Cell: ({ cell, row }) => {
//       return (
//         <IconButton
//           onClick={() =>
//             urnLoaded &&
//             onClick(
//               row.original.id,
//               row.original.projects?.[0],
//               "#main-nav=MCS&mcs-tab=BOM%20status"
//             )
//           }
//         >
//           <SummarizeIcon />
//         </IconButton>
//       );
//     },
//     grow: false,
//     enableEditing: false,
//     size: 60,
//   },
//   {
//     accessorKey: "mrq",
//     header: "MRQ",
//     // muiTableHeadCellProps: { align: "center" },
//     enableSorting: false,
//     enableColumnActions: false,
//     Cell: ({ cell, row }) => {
//       return (
//         <IconButton
//           onClick={() =>
//             onClick(
//               row.original.id,
//               row.original.projects?.[0],
//               "#main-nav=MCS&mcs-tab=MRQ"
//             )
//           }
//         >
//           <RequestQuoteIcon />
//         </IconButton>
//       );
//     },
//     grow: false,
//     enableEditing: false,
//     size: 60,
//   },
//   {
//     accessorKey: "preview",
//     header: "3D",
//     // muiTableHeadCellProps: { align: "center" },
//     enableSorting: false,
//     enableColumnActions: false,
//     Cell: ({ cell, row }) => {
//       return (
//         <IconButton
//           onClick={() =>
//             onClick(row.original.id, row.original.projects?.[0], "preview")
//           }
//         >
//           {urnLoaded ? <PreviewIcon /> : <HourglassBottomIcon />}
//         </IconButton>
//       );
//     },
//     grow: false,
//     enableEditing: false,
//     size: 60,
//   },
//   {
//     accessorKey: "4d",
//     header: "4D",
//     enableSorting: false,
//     // muiTableHeadCellProps: { align: "center" },
//     enableColumnActions: false,
//     Cell: ({ cell, row }) => {
//       return (
//         <IconButton
//           onClick={() =>
//             onClick(
//               row.original.id,
//               row.original.projects?.[0],
//               "#main-nav=PMS&pms-tab=4D"
//             )
//           }
//         >
//           <EventNoteIcon />
//         </IconButton>
//       );
//     },
//     grow: false,
//     enableEditing: false,
//     size: 60,
//   },
//   {
//     accessorKey: "5d",
//     header: "5D",
//     enableSorting: false,
//     // muiTableHeadCellProps: { align: "center" },
//     enableColumnActions: false,
//     Cell: ({ cell, row }) => {
//       return (
//         <IconButton
//           onClick={() =>
//             onClick(
//               row.original.id,
//               row.original.projects?.[0],
//               "#main-nav=PMS&pms-tab=5D"
//             )
//           }
//         >
//           <MonetizationOnIcon />
//         </IconButton>
//       );
//     },
//     grow: false,
//     enableEditing: false,
//     size: 60,
//   },
// ];

export const columns = (gotToMcs, openMembersModal, openCommentsModal) => [
  {
    accessorKey: "id",
    header: "Id",
    enableEditing: false,
    size: 80,
    Edit: () => <div></div>,
  },
  {
    accessorKey: "projectName",
    header: "Project Name",
    size: 500,
    muiEditTextFieldProps: ({ cell, row, table }) => ({
      required: true,
      // variant:"outlined",
      size: "small",
      sx: {
        p: "10px 0px 0px 0px",
      },
      InputLabelProps: {
        shrink: true,
        sx: {
          fontWeight: 700,
          fontSize: "18px",
          lineHeight: "26px",
          pb: 2,
          color: "black",
        },
      },
    }),
  },
  {
    accessorKey: "mcs",
    header: "MCS",
    size: 40,
    enableEditing: false,
    Edit: () => null,
    enableColumnActions: false,
    Cell: ({ cell, row }) => {
      const name = row.original.name;

      return (
        <IconButton
          onClick={() => {
            gotToMcs(row.original.id);
          }}
        >
          <DescriptionIcon color="primary" />
        </IconButton>
      );
    },
  },
  {
    accessorKey: "link",
    header: "DCS",
    size: 40,
    enableEditing: false,
    Edit: () => null,
    enableColumnActions: false,
    Cell: ({ cell, row }) => {
      const name = row.original.name;

      // For cbl
      // const dcsLink = "https://enerfacprojectscom.sharepoint.com/sites/CB-Lincoln/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FCB%2DLincoln%2FShared%20Documents%2F" + name

      // For cb
      const dcsLink = cell.getValue();
      // console.log('dec link', row.original);

      return (
        <a href={dcsLink} target="_blank" rel="noopener noreferrer">
          <img src={sp_icon} height={"40px"} alt="SharePoint" />
        </a>
      );
    },
  },
  {
    accessorKey: "raci",
    header: "RACI",
    size: 40,
    enableEditing: false,
    enableColumnActions: false,
    Edit: () => null,
    Cell: ({ row, cell }) => {
      return (
        <a
          href={`/projects/${row.original.id}/equipments/main_tank#main-nav=DCS&dcs-tab=Doc.%20Reviewer`}
        >
          <AssignmentIndIcon color="primary" />
        </a>
      );
    },
  },
  {
    accessorKey: "pms",
    header: "PMS",
    size: 40,
    enableEditing: false,
    enableColumnActions: false,
    Edit: () => null,
    Cell: ({ row, cell }) => {
      return (
        <a
          href={`/projects/${row.original.id}/equipments/main_tank#main-nav=PMS&pms-tab=4D`}
        >
          <CalendarTodayIcon color="primary" />
        </a>
      );
    },
  },
  {
    accessorKey: "tasks",
    header: "Tasks",
    size: 40,
    enableEditing: false,
    enableColumnActions: false,
    Edit: () => null,
    Cell: ({ row, cell }) => {
      return (
        <a href={`/projects/${row.original.id}/task`}>
          <TaskOutlinedIcon color="primary" />
        </a>
      );
    },
  },
  // {
  //   accessorKey: 'model',
  //   header: '3D',
  //   size: 40,
  //   enableEditing: false,
  //   Edit: () => null,
  //   enableColumnActions: false,
  //   Cell: ({ cell, row }) => {
  //     const name = row.original.name;
  //     const dcsLink =
  //       'https://enerfacprojectscom.sharepoint.com/sites/CB-Montreal/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FCB%2DMontreal%2FShared%20Documents/' +
  //       name;
  //     return (
  //       <a
  //         // target="_blank"
  //         style={{ cursor: 'pointer' }}
  //         onClick={() => {
  //           openViewerModal(row);
  //         }}
  //       >
  //         <img src={autodesk_icon} height={'30px'} alt="SharePoint" />
  //       </a>
  //     );
  //   },
  // },
  // {
  //   accessorKey: 'mrq',
  //   header: 'MRQ',
  //   size: 40,
  //   enableEditing: false,
  //   enableColumnActions: false,
  //   Edit: () => null,
  //   Cell: ({ row, cell }) => {
  //     return (
  //       <a href={`/projects/${row.original.id}/mrq`}>
  //         <RequestQuoteIcon color="primary" />
  //       </a>
  //     );
  //   },
  // },
  {
    accessorKey: "members",
    header: "Members",
    size: 150,
    Cell: ({ row, cell }) => {
      const members = cell.getValue();
      console.log("mmmmmm", members);

      const visibleMembers = members?.slice(0, 2);
      return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Box>
            {visibleMembers?.map((member, index) => (
              <Chip key={index} label={member.user.username} size="small" />
            ))}
            {members?.length > 2 && (
              <Tooltip title={members.slice(2).join(", ")} arrow>
                <Chip label={`${members.length - 2}+`} size="small" />
              </Tooltip>
            )}
          </Box>
          <IconButton onClick={() => openMembersModal(row.original.id)}>
            <InfoIcon />
          </IconButton>
        </Box>
      );
    },
    // muiEditTextFieldProps: ({ value, onChange }) => ({
    //   children: users.map((user, index) => (
    //     <MenuItem key={index} value={user}>
    //       {user}
    //     </MenuItem>
    //   )),
    //   select: true,
    //   SelectProps: {
    //     multiple: true,
    //     renderValue: (selected:any) => (
    //       <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
    //         {selected.map((value:any, index:any) => (
    //           <Chip key={index} label={value} />
    //         ))}
    //       </Box>
    //     ),
    //   },
    // }),
  },
];

// export default columns;
