import { Box, Paper, Tab, Tabs } from "@mui/material";
import React from "react";

import DrawingBomTab from "./DrawingBom";
import PoReconcile from "./PoReconcile";
import SPIRTab from "./SPIRTab";
import MRQTab from "./MRQTab";
import BidTab from "./BidTab";
import { MCSContext } from "./MCS.context";
import MasterMaterialTab from "./MasterMaterialTab";
import PoManger from "./PoManager";
import TabPanelFormat from "../../../../../components/widgets/TabPanelFormat";
import { getHashdata } from "../../PressureVessel/utils";
import { setHash } from "../../../../../components/Tabs/Utils/hashFunctions";

export default function Main() {
  const { currentTab, setCurrentTab } = React.useContext(MCSContext);

  const tabs = [
    {
      label: "Drawing BOM",
      panel: <DrawingBomTab />,
    },
    // {
    //   label: "BOM status",
    //   panel: <DrawingRegister />,
    // },
    {
      label: "MRQ",
      panel: <MRQTab />,
    },
    {
      label: "Bid Evaluation",
      panel: <BidTab />,
    },
    {
      label: "PO Manager",
      panel: <PoManger />,
    },
    {
      label: "Reconcile",
      panel: <PoReconcile />,
    },
    {
      label: "Materials Record",
      panel: <MasterMaterialTab />,
    },
    {
      label: "SPIR",
      panel: <SPIRTab />,
    },
  ];

  const navLabel = "mcs-tab";

  const fullHash = window.location.hash;
  React.useEffect(() => {
    const hashValue = getHashdata(fullHash, navLabel);
    const tabIndex = tabs.findIndex(
      (tab) => tab.label === decodeURIComponent(hashValue)
    );

    // console.log({ tabIndex });
    // if hash value for tabs is present, set it as currentTab
    if (tabIndex !== -1) {
      setCurrentTab(tabIndex);
    }
    // if its not present, but there is some existing hash value, add this to the hash value
    else if (fullHash)
      window.location.hash = fullHash + "&" + navLabel + "=" + tabs[0].label;
    // if not either, start a new hash
    else window.location.hash = navLabel + "=" + tabs[0].label;
  }, []);

  const handleChange = (event, newValue) => {
    console.log("CHNGE");
    setCurrentTab(newValue);

    setHash(fullHash, navLabel, tabs[newValue].label);
  };

  return (
    <Paper sx={{ width: "100%", display: "flex" }}>
      {/* <NavTabs
        tabs={tabs}
        // tabProps={verticalTabStyle}
        customCurrentTab={currentTab}
        setCustomCurrentTab={setCurrentTab}
        navLabel="mcs-tab"
        // vertical={true}
      /> */}

      <Box {...{ width: "100%", overflow: "auto" }}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          orientation={"horizontal"}
        >
          {tabs.map((tab, i) => {
            return <Tab label={tab.label} value={i} />;
          })}
        </Tabs>

        <TabPanelFormat {...tabs[currentTab].panelProps}>
          {tabs[currentTab].panel}
        </TabPanelFormat>
      </Box>
    </Paper>
  );
}
