import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";

export default function SelectDoc({
  onSelectExistedDoc,
  selectedMrqDoc,
  projectDocs,
}) {
  return (
    <FormControl fullWidth>
      {/* <InputLabel id="doc">Document #</InputLabel> */}
      <Select
        id="demo-simple-select"
        value={selectedMrqDoc}
        // label="Document #"
        onChange={onSelectExistedDoc}
        size="small"
        sx={{ p: 0 }}
      >
        {projectDocs.map((d) => {
          return (
            <MenuItem key={d.id} value={d}>
              {d.title}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
