import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import MainSelectedMaterialsTable from "../Tables/MainSelectedMaterials.table";
import { Divider, Grid } from "@mui/material";
import GroupingTable from "../Tables/GroupingTable";
import { DrawingBomContext } from "../../DrawingBomContext";
import GenerateTable from "../Tables/GenerateTable";
import { generatingProcuredData } from "../Tables/GenerateTable/utils";
import MrqForm from "../Tables/MrqForm";
import { modalStyle } from "../../../../../../../../components/modalStyle";

export default function ConsolidateModal() {
  const {
    openConsolidateModal,
    handleCloseConsolidateModal,
    consolidatedMaterials,
    generatingData,
    setGeneratingData,
    onClickAddMrq,
    isMrqFormVisible,
    onCreateMrq,
  } = React.useContext(DrawingBomContext);

  const onGenerate = () => {
    const generatedData = generatingProcuredData(generatingData);
    setGeneratingData(generatedData);
  };

  const isTaggedMaterials = !consolidatedMaterials?.[0]?.materialCode;

  const disableCreatingMrq = () => {
    const mrqMaterials = [];

    consolidatedMaterials?.forEach((m) => {
      m.mrqMaterials?.forEach((mm) => mrqMaterials.push(mm));
    });

    return mrqMaterials[0] ? true : false;
  };

  return (
    <div>
      <Modal
        open={openConsolidateModal}
        onClose={handleCloseConsolidateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          {!isTaggedMaterials && (
            <>
              <Typography variant="h6" sx={{ mb: 1 }}>
                Selected Materials: ({consolidatedMaterials.length})
              </Typography>

              <MainSelectedMaterialsTable />
              <Divider sx={{ my: 2 }} />
            </>
          )}

          <Grid container>
            <Grid item xs={5}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                Consolidated Materials:
              </Typography>
            </Grid>
            {!isTaggedMaterials && (
              <Grid item xs={7} sx={{ pl: 2, pb: 1 }}>
                <Button variant="outlined" onClick={onGenerate}>
                  Generate
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid container>
            <Grid item xs={isTaggedMaterials ? 7 : 5} sx={{ pr: 2 }}>
              <GroupingTable />
            </Grid>
            {!isTaggedMaterials && (
              <Grid item xs={7} sx={{ pl: 2 }}>
                <GenerateTable
                  generatingData={generatingData}
                  setGeneratingData={setGeneratingData}
                />
              </Grid>
            )}
          </Grid>
          {isMrqFormVisible && (
            <Box>
              <Divider sx={{ m: 2 }} />
              <MrqForm />
            </Box>
          )}

          <Divider sx={{ mt: 2 }} />

          <Box sx={{ textAlign: "right", pt: 2 }}>
            {disableCreatingMrq() && (
              <Typography
                sx={{ width: "100%", textAlign: "center", color: "red" }}
              >
                * Please make sure that all the selected Materials are not
                included in a previous MRQ
              </Typography>
            )}
            <Button
              variant="outlined"
              sx={{ mx: 0.5 }}
              onClick={handleCloseConsolidateModal}
            >
              Close
            </Button>

            {isMrqFormVisible ? (
              <Button
                variant="contained"
                sx={{ mx: 0.5 }}
                onClick={onCreateMrq}
              >
                Create Mrq
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{ mx: 0.5 }}
                onClick={onClickAddMrq}
                disabled={disableCreatingMrq()}
              >
                Add to Mrq
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
